<template>
  <div>
    <div class="card pagination-margin" qid="basic-info-section">
      <div class="card-body" v-if="(activity.additional_client && getClient.slug != activity.additional_client.slug) || activity.additional_client == null">

        <div class="card" v-if="open_tasks">
          <h5 class="text-dark">
            {{ $t('projects.tab.tasks') }}
            <button class="btn btn-secondary-dark float-right"
                    @click="downloadTasks"
                    qid="download-table-button"
                    v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.type && (activity.type == 'update' || activity.type == 'validation' || activity.type == 'security_measure' || activity.type == 'control')"
            >
              <font-awesome-icon icon="file-download"/>
            </button>
            <b-button class="float-right"
                      variant="light"
                      v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                      @click="sendReminders(object)"
                      qid="delete-s-cancel-button"
            >
              {{ $t('tasks.send_reminders') }}
            </b-button>

          </h5>

          <table class="table table-responsive-md table-list-secondary">
            <thead>
            <tr>
              <th>
                {{ $t('projects.table.name') }}
              </th>
              <th>
                {{ $t('table.asset') }}
              </th>
              <th>
                {{ $t('tasks.table.assignee') }}
              </th>
              <th>
                {{ $t('system.status') }}
              </th>
              <th width="210"></th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(object, index) in open_tasks" v-if="open_tasks && open_tasks.length" :id="'table-row-' + index">
                <b-popover
                    :target="'table-row-' + index"
                    boundary="viewport"
                    v-if="object.code == 'system_impact_assessment' || object.code == 'risk_assessment'"
                    triggers="hover"
                    placement="topleft"
                    :delay="{ show: 500, hide: 0 }"
                >
                  <div v-if="object.code == 'system_impact_assessment'">
                    <b-form-group>
                      <label qid="create-p-users-label">
                        {{ $t('processes.confidentiality.title') }}
                      </label>
                      <p v-if="object.confidentiality">
                        {{ object.confidentiality }}
                      </p>
                      <p v-if="!object.confidentiality">
                        {{ $t('system.no_results') }}
                      </p>
                    </b-form-group>
                    <b-form-group>
                      <label qid="create-p-users-label">
                        {{ $t('processes.integrity.title') }}
                      </label>
                      <p v-if="object.integrity">
                        {{ object.integrity }}
                      </p>
                      <p v-if="!object.integrity">
                        {{ $t('system.no_results') }}
                      </p>
                    </b-form-group>
                    <b-form-group>
                      <label qid="create-p-users-label">
                        {{ $t('processes.availability.title') }}
                      </label>
                      <p v-if="object.availability">
                        {{ object.availability }}
                      </p>
                      <p v-if="!object.availability">
                        {{ $t('system.no_results') }}
                      </p>
                    </b-form-group>
                    <b-form-group>
                      <label qid="create-p-users-label">
                        {{ $t('tasks.modal.rto_label') }}
                      </label>
                      <p v-if="object.rto_number">
                        {{ object.rto_number }} {{ object.rto_type }}
                      </p>
                      <p v-if="!object.rto_number">
                        {{ $t('system.no_results') }}
                      </p>
                    </b-form-group>
                    <b-form-group>
                      <label qid="create-p-users-label">
                        {{ $t('tasks.modal.rpo_label') }}
                      </label>
                      <p v-if="object.rpo_number">
                        {{ object.rpo_number }} {{ object.rpo_type }}
                      </p>
                      <p v-if="!object.rpo_number">
                        {{ $t('system.no_results') }}
                      </p>
                    </b-form-group>
                    <b-form-group>
                      <label qid="create-p-users-label">
                        {{ $t('tasks.modal.remarks_label') }}
                      </label>
                      <p class="text-formatted" v-if="object.remarks">{{ object.remarks }}</p>
                      <p v-if="!object.remarks">{{ $t('system.no_results') }}</p>
                    </b-form-group>
                  </div>
                  <div v-if="object.code == 'risk_assessment'">
                    <b-form-group>
                      <label qid="create-p-users-label">
                        {{ $t('tasks.modal.response_label') }}
                      </label>
                      <p v-if="object.response">
                        {{ object.response }}
                      </p>
                      <p v-if="!object.response">
                        {{ $t('system.no_results') }}
                      </p>
                    </b-form-group>
                    <b-form-group>
                      <label qid="create-p-users-label">
                        {{ $t('tasks.modal.remarks_label') }}
                      </label>
                      <p class="text-formatted" v-if="object.remarks">{{ object.remarks }}</p>
                      <p v-if="!object.remarks">
                        {{ $t('system.no_results') }}
                      </p>
                    </b-form-group>
                    <b-form-group>
                      <label qid="create-p-users-label">
                        {{ $t('maintenance.policies.create.attachments') }}
                      </label>
                      <p>
                        <span v-if="object.has_attachment">
                          <font-awesome-icon icon="paperclip"/>
                        </span>
                        <span v-if="!object.has_attachment">
                          {{ $t('system.no_results') }}
                        </span>
                      </p>
                    </b-form-group>
                  </div>
                </b-popover>
                <td>
                  <a href="#" v-if="object.code == 'business_risk_assessment' || object.code == 'privacy_risk_assessment' || object.code == 'system_impact_assessment'" @click="navigateToContent(object, 'model')">
                    <span class="mr-2 mt-2" v-if="object.model">
                      <font-awesome-icon icon="desktop" size="lg" class="mr-2"/>
                    </span>
                    <span v-if="object.model">
                      {{ object.model.name}}
                    </span>
                    <span class="text-muted" v-if="!object.model">
                      Content deleted
                    </span>
                  </a>
                  <span v-else>
                    <span v-if="activity.type && (activity.type == 'update' || activity.type == 'validation')">
                      {{ $t('tasks.values.' + $options.filters.slugify(object.title)) }}
                    </span>
                    <span v-else>
                      <span class="mr-2 mt-2" v-if="object.code == 'risk_assessment'">
                        <font-awesome-icon icon="question" size="lg" class="mr-2"/>
                      </span>
                      {{ object.title}}
                    </span>
                  </span>
                </td>
                <td>
                  <a href="#" v-if="object.code == 'system_impact_assessment'" @click="navigateToContent(object, 'scope')">
                    <span v-if="object.scope">
                      <font-awesome-icon icon="desktop" class="mr-1" v-if="object.scope_type == 'App\\System\\Models\\System'"/>
                      <font-awesome-icon icon="cubes" class="mr-1" v-if="object.scope_type == 'App\\Activity\\Models\\Activity'"/>
                      <font-awesome-icon icon="cube" class="mr-1" v-if="object.scope_type == 'App\\Process\\Models\\Process'"/>
                      <font-awesome-icon icon="file-signature" class="mr-1" v-if="object.scope_type == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'"/>
                      <font-awesome-icon icon="file-invoice" class="mr-1" v-if="object.scope_type == 'App\\Maintenance\\Policy\\Models\\Policy'"/>
                      {{ object.scope.name }}
                    </span>
                    <span v-if="!object.scope">
                      Content deleted
                    </span>
                  </a>
                  <a href="#" v-else @click="navigateToContent(object, 'model')">
                    <span v-if="object.model">
                      <font-awesome-icon icon="desktop" class="mr-1" v-if="object.model_type == 'App\\System\\Models\\System'"/>
                      <font-awesome-icon icon="cubes" class="mr-1" v-if="object.model_type == 'App\\Activity\\Models\\Activity'"/>
                      <font-awesome-icon icon="cube" class="mr-1" v-if="object.model_type == 'App\\Process\\Models\\Process'"/>
                      <font-awesome-icon icon="file-signature" class="mr-1" v-if="object.model_type == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'"/>
                      <font-awesome-icon icon="file-invoice" class="mr-1" v-if="object.model_type == 'App\\Maintenance\\Policy\\Models\\Policy'"/>
                      {{ object.model.name }}
                    </span>
                    <span class="text-muted" v-if="activity.type != 'security_measure' && !object.model">
                      Content deleted
                    </span>
                  </a>
                </td>
                <td>
                  <span class="mr-1" v-for="user in getReducedUsers(object.users)" v-if="object.users && object.users.length">
                    <span qid="profile-photo">
                      <Avatar :object="user" size="xs"></Avatar>
                    </span>
                  </span>
                  <span v-if="object.users && object.users.length > 3"
                        class="badge mr-1 rounded-circle"
                        @click="openUsersModal(object)"
                  >
                    <font-awesome-icon icon="users"/>
                    + {{ object.users.length - 3 }}
                  </span>
                  <button class="text-muted btn btn-sm btn-link mr-4 ml-2"
                          qid="edit-activity-modal"
                          @click="showEditUsersModal(object)"
                          v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                  >
                    <font-awesome-icon icon="pen"/>
                  </button>
                </td>
                <td>
                  <span v-if="object.status === 'completed'" >
                  <font-awesome-icon icon="check-circle" class="icon-success"/>
                  </span>
                  <span v-if="object.status === 'pending'" class="pr-2">
                    <font-awesome-icon
                        icon="exclamation-triangle"
                        class="risk-6"
                    />
                  </span>
                  {{ $t('system.' + $options.filters.slugify(object.status)) }}
                </td>
                <td class="text-right">
                  <span class="btn btn-edit"
                        qid="delete-s-modal" @click="showViewTaskModal(object)"
                        v-if="object.status === 'completed' && (activity.type == 'security_measure' || activity.type == 'control' || activity.type == 'risk_assessment' || activity.type == 'bia_assessment')"
                  >
                    <font-awesome-icon icon="eye" />
                  </span>
                  <AssessmentSingleTaskModal
                      :task="object"
                      v-if="object.model && (activity.type == 'risk_assessment' || activity.type == 'bia_assessment') && isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                      @reloadContent="reloadTasks"
                  ></AssessmentSingleTaskModal>
                  <RiskSingleTaskModal
                      :task="object"
                      @reloadContent="reloadTasks"
                      v-if="object.model && object.scope && (activity.type == 'security_measure' || activity.type == 'control') && isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                  ></RiskSingleTaskModal>
                  <UpdateSingleTaskModal
                      :task="object"
                      @reloadContent="reloadTasks"
                      v-if="object.model && (activity.type == 'update') && isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                  ></UpdateSingleTaskModal>
                  <ValidationSingleTaskModal
                      :task="object"
                      @reloadContent="reloadTasks"
                      v-if="object.model && (activity.type == 'validation') && isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                  ></ValidationSingleTaskModal>
                  <span v-if="activity.type == 'security_measure' || activity.type == 'control'">
                    <b-button
                        variant="delete"
                        @click="showConfirmDeleteModal(object)"
                        v-if="object.scope && object.model && isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                        qid="delete-s-cancel-button"
                    >
                      <font-awesome-icon icon="trash-alt"/>
                    </b-button>
                  </span>
                  <span v-else>
                    <b-button
                        variant="delete"
                        @click="showConfirmDeleteModal(object)"
                        v-if="object.model && isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], activity) && activity.status != 'closed' && activity.status != 'completed'"
                        qid="delete-s-cancel-button"
                    >
                      <font-awesome-icon icon="trash-alt"/>
                    </b-button>
                  </span>
                </td>
              </tr>
              <tr v-if="open_tasks && !open_tasks.length && !loadingTasks" qid="no-results-row" class="text-center">
                <td colspan="10">
                  <h5 class="mt-3">
                    {{ $t('system.no_results') }}
                  </h5>
                </td>
              </tr>
              <tr v-if="loadingTasks" qid="no-results-row-rr" class="text-center">
                <td colspan="10">
                  <div class="mt-2 mb-4">
                    <div class="swing-container">
                      <div class="swing">
                        <div class="swing-l"></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div class="swing-r"></div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="mt-3 pagination row">
            <div class="col-md-6">
              <div class="row">
                <div class="float-left">
                  <span class="text-muted pagination-counter">
                    {{ $t('pagination.entries_per_page') }}:
                  </span>
                </div>
                <div class="float-left">
                  <multiselect
                      v-model="tasksPerPage"
                      :options="perPageOptions"
                      track-by="value"
                      label="value"
                      :select-label="$t('system.dropdown_select_label')"
                      :selected-label="$t('system.dropdown_selected_label')"
                      :deselect-label="$t('system.dropdown_deselect_label')"
                      qid="create-org-form-organization-level"
                      @select="tasksPerPageChanged"
                      :placeholder="tasksSelectedPerPage"
                      @remove="tasksPerPageRemoved"
                  ></multiselect>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <b-pagination
                  class="float-right"
                  v-model="tasksCurrentPage"
                  :total-rows="tasksRows"
                  :per-page="tasksSelectedPerPage"
                  aria-controls="my-table-1"
                  @change="tasksPageChanged"
                  limit="1"
              >
                <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
                <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
                <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
                <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
                <div slot="ellipsis-text">
                  <b-spinner small type="grow"></b-spinner>
                  <b-spinner small type="grow"></b-spinner>
                  <b-spinner small type="grow"></b-spinner>
                </div>
                <span class="text-muted" slot="page" slot-scope="{ page, active }">
                  <span v-if="active">{{getTasksPerPage}}</span>
                </span>
              </b-pagination>
            </div>
          </div>

        </div>
      </div>
    </div>


    <!-- Re-assign modal -->
    <b-modal ref="re-assign" hide-footer @hide="hideEditUsersModal">
      <div class="d-block text-center">
        <h4 qid="update-tp-title">
          {{ $t('tasks.re_assign.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="reassignFormSubmit" qid="create-p-form" v-if="selectedTask && selectedTask.model">
        <div class="form-container">
          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('projects.create.owners_label') }}
            </label>
            <multiselect
                v-model="selectedAssignUsers"
                :options="allUsers"
                :multiple="true"
                :taggable="false"
                @search-change="loadUsers"
                track-by="slug"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                :placeholder="$t('projects.create.owners_placeholder')"
                label="name"
                qid="create-p-users-select"
            >
              <template slot="tag" slot-scope="props">
                <span class="mr-3 user-tag">
                  <Avatar :object="props.option" size="xs"></Avatar>
                  <span class="ml-2">{{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
          </b-form-group>
        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideEditUsersModal"
                    qid="create-p-cancel-button"
          >
            {{ $t('projects.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="create-p-submit-button"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('projects.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- Delete task modal template -->
    <b-modal ref="delete-task" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-ac-title">
          {{ $t('activities.system.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('activities.system.delete.description') }}
        <span v-if="itemToDelete">{{ itemToDelete.title }}</span>
      </h5>
      <h5 class="text-center">
        {{ $t('activities.system.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideSConfirmDeleteModal"
                  qid="delete-ac-cancel-button"
        >
          {{ $t('activities.system.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeleteS(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-ac-submit-button"
        >
          {{ $t('activities.system.delete.submit') }}
        </b-button>
      </div>
    </b-modal>


    <TaskViewModal
        :selectedTask="selectedTask"
        :showModal="viewTaskModal"
        @closeViewModal="hideViewTaskModal"
    ></TaskViewModal>

    <ShowMoreModal
        :items="selectedItem.users"
        :show="showUsersModal"
        @closeViewModal="closeUsersModal"
        type="users"
    ></ShowMoreModal>
  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import Datepicker from 'vuejs-datepicker';
  import ShowMoreModal from '@/components/ShowMoreModal.vue';
  import AssessmentSingleTaskModal from '@/views/Tasks/AssessmentSingleTaskModal.vue';
  import RiskSingleTaskModal from '@/views/Tasks/RiskSingleTaskModal.vue';
  import UpdateSingleTaskModal from '@/views/Tasks/UpdateSingleTaskModal.vue';
  import ValidationSingleTaskModal from '@/views/Tasks/ValidationSingleTaskModal.vue';
  import TaskViewModal from '@/views/Tasks/TaskViewModal.vue';
  import DownloadAllAttachmentsModal from '@/views/Projects/DownloadAllAttachmentsModal.vue';


  export default {
    name: 'TasksTable',
    data: function() {
      return {
        sForm: {
          items: []
        },
        form: {
          due_date: '',
          start_date: '',
          message: ''
        },
        eform: {
          due_date: '',
          start_date: '',
          message: ''
        },
        activity: {},
        sTableItems: [],
        selectedS: [],
        sLoading: true,
        error: '',
        itemToDelete: {},
        itemObject: {},
        buttonDisabled: false,
        open_tasks: {},
        loadingTasks: false,
        closed_tasks: {},
        showStatusForm: false,
        selectedStatus: [],
        options: [
          { name: this.$t('projects.status.pending'), value: 'pending' },
          { name: this.$t('projects.status.in_progress'), value: 'in progress' },
          { name: this.$t('projects.status.completed'), value: 'completed' },
          { name: this.$t('projects.status.closed'), value: 'closed' }
        ],
        selectedTask: [],
        selectedAssignUsers: [],
        selectedItem: {},
        showUsersModal: false,
        viewTaskModal: false,
        allUsers: [],
        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],

        // Tasks pagination
        tasksPerPage: '',
        tasksCurrentPage: 1,
        tasksSelectedPerPage: "10",
        tasksTotalRows: 0,
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      }
    },
    components: {
      Avatar,
      Datepicker,
      ShowMoreModal,
      RiskSingleTaskModal,
      AssessmentSingleTaskModal,
      TaskViewModal,
      UpdateSingleTaskModal,
      ValidationSingleTaskModal,
      DownloadAllAttachmentsModal
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getFiles() {
        return this.selectedFiles
      },
      getUser() {
        return this.$store.state.user
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getTasksPerPage() {
        let page = this.tasksCurrentPage;
        let selectedFrom = (page-1) * Number(this.tasksSelectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.tasksSelectedPerPage) + Number(this.tasksSelectedPerPage);
        let selectedOf = this.tasksTotalRows;

        if (selectedTo > selectedOf) {
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      },
      tasksRows() {
        // Use computed value for rows because of dynamic update
        return this.tasksTotalRows
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
      },
      object: function (value) {
        this.activity = value
      }
    },
    methods: {
      debounceInput: _.debounce(function() {

      }, 400),
      reloadTasks() {
        this.loadTasks(this.tasksCurrentPage)
      },
      tasksPageChanged(page) {
        this.loadTasks(page)
      },
      tasksPageChanged(page) {
        this.loadTasks(page)
      },
      navigateToContent(object, type) {
        if (type == 'model') {
          if (object.model_type == 'App\\Activity\\Models\\Activity') {
            this.$router.push({ name: 'activities.view', params: { slug: object.model.slug }})
          }
          if (object.model_type == 'App\\Process\\Models\\Process') {
            this.$router.push({ name: 'processes.view', params: { slug: object.model.slug }})
          }
          if (object.model_type == 'App\\System\\Models\\System') {
            this.$router.push({ name: 'systems.view', params: { slug: object.model.slug, }})
          }
          if (object.model_type == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty') {
            this.$router.push({ name: 'contracts.view', params: { slug: object.model.slug, }})
          }
        } else {
          if (object.scope_type == 'App\\Activity\\Models\\Activity') {
            this.$router.push({ name: 'activities.view', params: { slug: object.scope.slug }})
          }
          if (object.scope_type == 'App\\Process\\Models\\Process') {
            this.$router.push({ name: 'processes.view', params: { slug: object.scope.slug }})
          }
          if (object.scope_type == 'App\\System\\Models\\System') {
            this.$router.push({ name: 'systems.view', params: { slug: object.scope.slug, }})
          }
          if (object.scope_type == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty') {
            this.$router.push({ name: 'contracts.view', params: { slug: object.scope.slug, }})
          }
        }
      },
      tasksPerPageChanged(perPage) {
        let self = this;
        self.tasksSelectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.tasksSelectedPerPage);
        // Load content when page changed listener is triggered
        this.loadTasks(1, perPage.value)
      },
      tasksPerPageRemoved() {
        let self = this;
        // Load content when page changed listener is triggered
        self.tasksSelectedPerPage = self.perPageOptions[0].value
        this.loadTasks(1, self.tasksSelectedPerPage)
      },
      showViewTaskModal(object) {
        this.selectedTask = object
        this.viewTaskModal = true
      },
      hideViewTaskModal() {
        this.selectedTask = {}
        this.viewTaskModal = false
      },
      showConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-task'].show()
      },
      hideConfirmDeleteModal() {
        this.itemToDelete = {}
        this.$refs['delete-task'].hide()
      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      resetFields() {
        this.selectedRC = null
      },
      cancelForm() {
        this.showStatusForm = false
      },
      hideEditUsersModal() {
        this.selectedTask = {}
        this.$refs['re-assign'].hide()
      },
      showEditUsersModal(object) {
        let self = this;
        this.error = '';

        let role_name = [self.permissions.ROLE_CLIENT_ADMIN];

        role_name.push(self.permissions.ROLE_MEMBER);
        role_name.push(self.permissions.ROLE_VALIDATOR);

        if (this.getClient && this.getClient.parent) {
          role_name.push(self.permissions.ROLE_PARTITION_ADMIN);
        }

        this.selectedAssignUsers = [];
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            role_name: role_name,
            per_page: 100
          }
        }).then(function(response) {
          let users = response.data.items
          _.forEach(object.users, function (user) {
            if (_.findIndex(users, function(o) { return o.id == user.id; }) == -1) {
              if (user.id != self.getUser.id) {
                users.push(user)
              }
            }

            let matchedUser = _.cloneDeep(user)
            if (user.id == object.owner) {
              matchedUser.owner = true
            }
            self.selectedAssignUsers.push(matchedUser)
          })
          self.allUsers = _.uniq(users)
        }, function() {});

        this.selectedTask = object
        this.$refs['re-assign'].show()
      },
      loadUsers(query) {
        let self = this;

        let role_name = [self.permissions.ROLE_CLIENT_ADMIN];

        role_name.push(self.permissions.ROLE_MEMBER);
        role_name.push(self.permissions.ROLE_VALIDATOR);

        if (this.getClient && this.getClient.parent) {
          role_name.push(self.permissions.ROLE_PARTITION_ADMIN);
        }

        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            role_name: role_name,
            per_page: 50
          }
        }).then(function(response) {
          self.allUsers = response.data.items
          if (self.allUsers && !self.allUsers.length && !query) {
            self.allUsers.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      reassignFormSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        let userIds = [];
        evt.preventDefault();

        if (this.selectedAssignUsers && this.selectedAssignUsers.length) {
          for (var i = 0; i < this.selectedAssignUsers.length; i++) {
            userIds.push(this.selectedAssignUsers[i].id);
          }
        }

        if (userIds && userIds.length) {
          piincHttp.put('tasks/' + this.selectedTask.id, {
            users: userIds
          }).then(function(response) {
            self.hideEditUsersModal();
            self.loadTasks()
            self.$toastr('success', self.$t('tasks.re_assign.success'))
            self.buttonDisabled = false;
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          self.error = self.$t('tasks.re_assign.required')
          self.buttonDisabled = false;
        }
      },
      showFormFunction() {
        let self = this
        this.selectedStatus = []
        if (this.selectedS.status) {
          this.selectedStatus = _.find(this.options, function(item) {
            return item.value === self.selectedS.status;
          });
        }
        this.showStatusForm = true
      },
      formStatusSubmit() {
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;

        this.form.processing_role = this.processingRole ? this.processingRole.value : '';

        piincHttp.put('projects/' + this.selectedS.slug, {
          status: this.selectedStatus.value
        }).then(function(response) {
          self.selectedS = response.data
          self.cancelForm();
          self.loadProjects();
          self.$toastr('success', self.$t('projects.update.success'))
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      sendReminders(object) {
        let self = this;
        // Call API for reminders
        piincHttp.get('projects/' + object.slug + '/reminders').then(function(response) {
          self.$toastr('success', self.$t('projects.create.project_reminders_success'))
        }, function() {});
      },
      openTasksTabClicked() {
        this.loadTasks()
        this.showClosedTasksTab = false
        this.showOpenTasksTab = true
      },
      closedTasksTabClicked() {
        this.loadClosedTasks()
        this.showClosedTasksTab = true;
        this.showOpenTasksTab = false
      },
      loadTasks(page, perPage) {
        let self = this;
        this.loadingTasks = true;
        this.open_tasks = []

        if (this.getStorePerPage) {
          this.tasksSelectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.tasksSelectedPerPage;

        // Call API for open tasks
        piincHttp.get('projects/' + this.object.slug + '/tasks/small', { params:
          {
            client: this.getClient.slug,
            page: pageNumber,
            per_page: perPageNumber,
          }
        }).then(function(response) {
          self.open_tasks = response.data.items;
          // Set number of total items
          self.tasksTotalRows = response.data.total_count;
          self.loadingTasks = false;
          self.$emit('updated');
        }, function() {});
      },
      loadClosedTasks() {
        let self = this;
        // Call API for closed tasks
        piincHttp.get('projects/' + this.object.slug + '/tasks', { params:
          {
            status: 'Completed',
            client: this.getClient.slug,
            per_page: 100
          }
        }).then(function(response) {
          self.closed_tasks = response.data.items;
        }, function() {});
      },
      downloadTasks() {
        let self = this;
        // Call API for open tasks
        piincHttp.get('projects/' + this.object.slug + '/tasks/download',{responseType: 'blob'}).then(function(response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Project tasks.xlsx');

          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);
        }, function() {});


      },
      getReducedUsers(users) {
        let reducedUsers = []
        if (users && users.length > 3) {
          reducedUsers = _.cloneDeep(users);
          reducedUsers = reducedUsers.slice(0, 3);
        } else {
          reducedUsers = users;
        }
        return reducedUsers;
      },
      showEditModal(object) {
        let self = this;
        this.error = '';

        this.selectedS = object
        this.loadTasks()
        this.loadClosedTasks()
        this.eform.due_date = this.object.end_date;
        this.$refs['edit-item'].show()
      },
      hideEditModal() {
        this.selectedS = {}
        this.selectedUsers = [],
        this.$refs['edit-item'].hide()
      },
      editFormSubmit(evt) {
        var moment = require('moment');
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        this.eform.due_date = this.eform.due_date ? moment(this.eform.due_date).format('YYYY-MM-DD') : '';

        piincHttp.put('projects/' + this.selectedS.slug, {end_date: this.eform.due_date}).then(function(response) {
          self.hideEditModal();
          self.loadProjects();
          self.$toastr('success', self.$t('projects.update.success'))
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      showSConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-ac'].show()
      },
      hideSConfirmDeleteModal() {
        this.itemToDelete = {}
        this.$refs['delete-task'].hide()
      },
      showEditSModal(object) {
        this.eform.due_date = object.end_date;
        this.$refs['edit-system'].show()
      },
      hideEditSModal() {
        this.$refs['edit-system'].hide()
      },
      confirmDeleteS(object) {
        let self = this;
        piincHttp.delete('tasks/' + object.id).then(function() {
          self.hideSConfirmDeleteModal();
          self.loadTasks();
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('tasks.delete.success'))
          self.$emit('updated');
        }, function() {});
      },
      showCreateModal() {
        let self = this;
        this.error = '';
        this.$refs['create-item'].show()
      },
      hideCreateModal() {
        this.selectedS = {}
        this.selectedUsers = []
        this.form.due_date = ''
        this.form.start_date = ''
        this.$refs['create-item'].hide()
      },
      createFormSubmit(evt) {
        var moment = require('moment');
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        let formData = new FormData();

        this.form.due_date = this.form.due_date ? moment(this.form.due_date).format('YYYY-MM-DD') : '';
        formData.append("due_date", this.form.due_date);

        this.form.start_date = this.form.start_date ? moment(this.form.start_date).format('YYYY-MM-DD') : '';
        formData.append("start_date", this.form.start_date);

        formData.append("message", this.form.message);

        piincHttp.post('projects/' + this.$route.params.slug + '/projects', formData, {

        }).then(function(response) {
          self.hideCreateModal();
          self.loadProjects();
          // Emit event to parent component to update newest validation
          self.$toastr('success', self.$t('projects.create.project_success'))
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
            if (error.data.errors.start_date) {
              self.error += ' ' + error.data.errors.start_date[0]
            }
            if (error.data.errors.due_date) {
              self.error += ' ' + error.data.errors.due_date[0]
            }
          }
        })

      },
    },
    created () {
      this.activity = this.object;
      if (this.getClient && this.getClient.slug) {

      }
      // Table items
      this.loadTasks();
    }
  }
</script>
