<template>
  <span>
    <b-button class="mr-2"
              variant="edit"
              @click="showTaskModal(task)"
              qid="delete-s-cancel-button"
              v-if="!editIcon"
    >
      <span v-if="task">
        <font-awesome-icon icon="pen"/>
      </span>
      <span v-if="!task">
        {{ $t('tasks.open_first_task') }}
      </span>
    </b-button>

    <!-- Task modal -->
    <b-modal ref="task-modal" @hide="hideTaskModal" size="xl" modal-class="bottom-left task-modal">

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideTaskModal" class="btn btn-secondary cursor-pointer float-right">
            <font-awesome-icon icon="window-minimize"/>
          </span>
          <h5 class="mb-1 text-center" v-if="selectedTask && selectedTask.id && selectedTask.model">
            {{ $t('tasks.values.' + $options.filters.slugify(selectedTask.title)) }}
            {{ $t('system.on') }}
            <span v-if="selectedTask.model.who_am_i == 'App\\System\\Models\\System'">
              {{ $t('system.the_system') }}
            </span>
            <span v-if="selectedTask.model.who_am_i == 'App\\Activity\\Models\\Activity'">
              {{ $t('system.the_activity') }}
            </span>
            <span v-if="selectedTask.model.who_am_i == 'App\\Process\\Models\\Process'">
              {{ $t('system.the_process') }}
            </span>
            <span v-if="selectedTask.model.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'">
              {{ $t('system.the_contract') }}
            </span>
            <span v-if="selectedTask.model.who_am_i == 'App\\Maintenance\\Policy\\Models\\Policy'">
              {{ $t('system.the_policy') }}
            </span>
            <span class="breadcrumb-item ml-2">
              <a @click="navigateToTask(selectedTask)">
                <font-awesome-icon icon="desktop" class="mr-1" v-if="selectedTask.model.who_am_i == 'App\\System\\Models\\System'"/>
                <font-awesome-icon icon="cubes" class="mr-1" v-if="selectedTask.model.who_am_i == 'App\\Activity\\Models\\Activity'"/>
                <font-awesome-icon icon="cube" class="mr-1" v-if="selectedTask.model.who_am_i == 'App\\Process\\Models\\Process'"/>
                <font-awesome-icon icon="file-signature" class="mr-1" v-if="selectedTask.model.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'"/>
                <font-awesome-icon icon="file-invoice" class="mr-1" v-if="selectedTask.model.who_am_i == 'App\\Maintenance\\Policy\\Models\\Policy'"/>

                {{ selectedTask.model.name }}
              </a>
            </span>
            <span v-if="selectedTask.model.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'">
              {{ $t('system.with') }}
              <Avatar :object="selectedTask.model.vendors[0]" size="xs"></Avatar>
              <span class="ml-1">{{ selectedTask.model.vendors[0].name }}</span>
            </span>
          </h5>
          <div class="row mt-3 pt-4 pb-1 pl-5 pr-5" v-if="selectedTask && selectedTask.id">
            <div class="col-sm-6"
                 v-if="getGuide(selectedTask.code)"
            >
              <b-form-group>
                <div class="">
                  <span class="btn btn-secondary btn-xs float-right cursor-pointer" @click="openGuide()">
                    <font-awesome-icon icon="eye" />
                    {{ $t('administration.guide.modal.button') }}
                  </span>
                  <label qid="create-p-users-label">
                    {{ $t('system.help_center_label') }}
                  </label>
                  <div v-if="wpGuide && wpGuide.id" class="guide-content-container">
                    <p v-if="!showFullGuideDescription" v-html="wpGuide.excerpt.rendered"></p>
                    <p v-if="showFullGuideDescription" v-html="wpGuide.content.rendered"></p>

                    <div class="text-center mt-3" @click="showFullGuideDescription=!showFullGuideDescription">
                      <span class="cursor-pointer" v-if="!showFullGuideDescription">
                        <span class="mr-2">
                          {{ $t('system.view_more') }}
                        </span>
                        <font-awesome-icon icon="chevron-down"/>
                      </span>
                      <span class="cursor-pointer" v-if="showFullGuideDescription">
                        <span class="mr-2">
                          {{ $t('system.view_less') }}
                        </span>
                        <font-awesome-icon icon="chevron-up"/>
                      </span>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </div>
            <div :class="{'col-sm-6': getGuide(selectedTask.code), 'col-sm-12': !getGuide(selectedTask.code)}">
              <b-form-group>
                <div class="">
                  <span class="btn btn-secondary btn-xs float-right cursor-pointer" @click="openWelcomePage()" v-if="welcomeGuide && welcomeGuide.id">
                    <font-awesome-icon icon="eye" />
                    {{ $t('system.welcome_page_button') }}
                  </span>
                  <label qid="create-p-users-label">
                    <font-awesome-icon icon="calendar-alt" class="mr-2"/>
                    {{ selectedTask.project.title }}
                  </label>
                  <div v-if="selectedTask.project.schedule_message && selectedTask.project.schedule_message.length > 300">
                    <p v-if="showFullProjectMessage" class="text-formatted">
                      {{ selectedTask.project.schedule_message }}
                    </p>
                    <p v-if="!showFullProjectMessage" class="text-formatted">
                      {{ selectedTask.project.schedule_message | truncate(300) }}
                    </p>
                    <div class="text-center mt-3" @click="showFullProjectMessage=!showFullProjectMessage">
                      <span class="cursor-pointer" v-if="!showFullProjectMessage">
                        <span class="mr-2">
                          {{ $t('system.view_more') }}
                        </span>
                        <font-awesome-icon icon="chevron-down"/>
                      </span>
                      <span class="cursor-pointer" v-if="showFullProjectMessage">
                        <span class="mr-2">
                          {{ $t('system.view_less') }}
                        </span>
                        <font-awesome-icon icon="chevron-up"/>
                      </span>
                    </div>
                  </div>
                  <p v-if="selectedTask.project.schedule_message && selectedTask.project.schedule_message.length <= 300"
                     class="text-formatted"
                  >
                    {{ selectedTask.project.schedule_message }}
                  </p>
                </div>
                <div class="mt-2 mb-1" v-if="selectedTask.project.media">
                  <p v-for="file in selectedTask.project.media">
                    <span class="mr-3 float-right text-muted cursor-pointer"
                          @click="downloadMedia(file)"
                          qid="download-media"
                    >
                      <font-awesome-icon icon="cloud-download-alt" />
                    </span>
                    <a @click="downloadMedia(file)" qid="create-p-media-item">
                      {{ file.file_name }}
                    </a>
                  </p>
                </div>

                <div class="" v-if="selectedTask.project.links">
                  <p v-for="link in selectedTask.project.links" >
                    <a :href="link.url" target="_blank" qid="create-p-link-item">
                      {{ link.name ? link.name : link.url }}
                    </a>
                  </p>
                </div>
              </b-form-group>
            </div>
          </div>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <b-form qid="create-p-form" v-if="selectedTask && selectedTask.model">
        <div class="form-container mt-0 mb-2">

          <div class="row mt-1"
               v-if="
                  selectedTask.code != 'validate_access_control' &&
                  selectedTask.code != 'validate_log_management' &&
                  selectedTask.code != 'validate_network' &&
                  selectedTask.code != 'validate_data_discovery'
                "
          >

            <!-- Tables container -->
            <div class="col-sm-12">
              <span class="btn btn-light cursor-pointer float-right"
                    @click="showValidationForm"
              >
                <font-awesome-icon icon="check-double"/> {{ $t('validations.create.button_label') }}
              </span>

              <h5 class="mb-2 mt-2" v-if="selectedTask">
                &nbsp;
              </h5>
            </div>

            <div class="col-sm-12">
              <b-form @submit="createValidationFormSubmit" qid="create-validation-form" v-if="showForm">
                <div class="row">
                  <div class="col-sm-6" v-if="checklist">
                    <div class="form-container">
                      <b-form-group>
                        <label qid="create-validation-checklist-label">
                          {{ $t('validations.create.checklist_label') }}
                        </label>
                        <br>
                        <div v-for="(value,key) in checklist">
                          <p class="mt-2">
                            {{ $t('validations.checklist.' + $options.filters.slugify(key)) }}
                          </p>
                          <div v-for="item in value" class="row">
                            <div class="col-sm-6">
                              <p class="text-normal smaller" :class="{'text-muted': disabledArray && disabledArray.indexOf(item.title) > -1}">
                                {{ $t('validations.checklist.' + $options.filters.slugify(item.title)) }}
                              </p>
                            </div>
                            <div class="col-sm-6 text-right" v-if="validationForm.checklist[key]">
                              <b-form-radio-group
                                :disabled="disabledArray && disabledArray.indexOf(item.title) > -1"
                                v-model="validationForm.checklist[key][item.title]"
                                :options="checklistOptions"
                                qid="create-validation-appropriate-measures"
                              ></b-form-radio-group>
                            </div>
                          </div>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                  <div :class="{'col-sm-6': checklist, 'col-sm-12': !checklist}">
                    <div class="form-container">
                      <b-form-group>
                        <label qid="create-validation-status-label">
                          {{ $t('validations.create.status_label') }}
                        </label>
                        <multiselect
                            v-model="validationForm.validation"
                            :options="validationOptions"
                            :multiple="false"
                            :taggable="false"
                            label="text"
                            :select-label="$t('system.dropdown_select_label')"
                            :selected-label="$t('system.dropdown_selected_label')"
                            :deselect-label="$t('system.dropdown_deselect_label')"
                            :placeholder="$t('validations.create.status_label')"
                        ></multiselect>
                      </b-form-group>
                      <b-form-group>
                        <label qid="create-validation-comment-label">
                          {{ $t('validations.create.comment_label') }}
                        </label>
                        <b-form-textarea
                          qid="create-validation-comment"
                          v-model="validationForm.comment"
                          rows="6"
                          :required="true"
                          max-rows="6"
                          :placeholder="$t('validations.create.comment_placeholder')"
                        ></b-form-textarea>
                      </b-form-group>

                      <b-form-group>
                        <label qid="create-tp-attachments-label">
                          {{ $t('validations.create.attachments_label') }}
                          &nbsp;
                          <span class="text-muted">({{ $t('attachments.file_size_label') }})</span>
                        </label>
                        <br>
                        <p v-for="file in getFiles" :key="file.name">{{ file.name }}</p>
                        <label class="btn btn-secondary cursor-pointer" qid="validations-attachments-button">
                          <input type="file"
                                 name="fileupload"
                                 value="fileupload"
                                 multiple
                                 id="file"
                                 @change="onFileChange"
                                 @click="resetFile"
                          >
                          <span>
                            <font-awesome-icon icon="cloud-upload-alt" />
                            {{ $t('system.upload') }}
                          </span>
                        </label>
                      </b-form-group>
                      <b-form-group>
                        <label qid="validations-link-label">
                          {{ $t('validations.create.links_label') }}
                        </label>
                        <p v-for="(link, index) in validationForm.links">
                          <span class="float-right text-muted cursor-pointer"
                                @click="removeLinkItem(index)"
                                qid="validations-link-remove"
                          >
                            <font-awesome-icon icon="trash-alt" />
                          </span>
                          <a :href="link.url" target="_blank" qid="create-p-link-item">{{ link.name ? link.name : link.url }}</a>
                        </p>
                        <div>
                          <label class="mt-2">{{ $t('system.link') }}</label>
                          <b-form-input
                            type="text"
                            aria-describedby="inputGroup"
                            v-model="inputLink"
                            :placeholder="$t('maintenance.third_parties.create.links_placeholder')"
                            qid="create-p-links"
                          ></b-form-input>
                          <label class="mt-2">{{ $t('table.name') }}</label>
                          <b-form-input
                            type="text"
                            aria-describedby="inputGroup"
                            v-model="inputLinkName"
                            :placeholder="inputLink ? inputLink : $t('table.name')"
                            qid="create-p-links"
                          ></b-form-input>
                        </div>
                        <span class="btn btn-link cursor-pointer float-right"
                              @click="handleLink"
                              qid="validations-add-link"
                        >
                          <font-awesome-icon icon="plus"/> {{ $t('system.add_link') }}
                        </span>
                      </b-form-group>
                    </div>
                  </div>
                </div>


                <div class="text-right">
                  <b-button class="mt-3"
                            variant="secondary"
                            @click="hideValidationForm"
                            qid="edit-validation-cancel-button"
                  >
                    {{ $t('validations.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="mt-3 ml-3"
                            variant="success"
                            :disabled="buttonDisabled"
                            qid="edit-validation-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('validations.create.submit') }}
                  </b-button>
                </div>
              </b-form>
            </div>


            <table class="table table-responsive-md mb-2">
              <thead>
              <tr>
                <th scope="col" class="cursor-pointer">
                  {{ $t('validations.create.status_label') }}
                </th>
                <th scope="col" class="cursor-pointer">
                  {{ $t('validations.create.owner_label') }}
                </th>
                <th scope="col" class="cursor-pointer">
                  {{ $t('validations.create.comment_label') }}
                </th>
                <th scope="col" class="cursor-pointer">
                  {{ $t('validations.create.date_label') }}
                </th>
                <th width="150" v-if="tableItems.length"></th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="validation in tableItems" :qid="'validations-list-item' + type">
                  <td>
                    <span v-if="validation.validation === 1" :qid="'validations-status-approved-' + type">
                      <font-awesome-icon icon="check-circle" class="icon-success"/>
                      {{ $t('system.approved') }}
                    </span>
                    <span v-if="validation.validation === 0" :qid="'validations-status-rejected' + type">
                      <font-awesome-icon icon="times-circle" class="icon-danger"/>
                      {{ $t('system.rejected') }}
                    </span>
                  </td>
                  <td>
                    <img v-if="validation.updated_by"
                         v-bind:src="getUserAvatar(validation.updated_by)"
                         class="rounded-circle mr-3"
                         width="30"
                         height="30"
                         qid="profile-photo"
                    >
                    <span v-if="validation.updated_by">{{ validation.updated_by.name }}</span>
                  </td>
                  <td>{{ validation.comment | truncate(50)}}</td>
                  <td>{{ validation.created_at | formatDate }}</td>
                  <td>
                    <span @click="showViewModal(validation)"
                            class="btn btn-link btn-xs mr-3 cursor-pointer"
                            :qid="'validations-view-modal-' + type"
                    >
                      {{ $t('system.view_more') }}
                    </span>
                  </td>
                </tr>
                <tr v-if="!loading && !tableItems.length" :qid="'no-results-row' + type" class="text-center">
                  <td colspan="10">
                    {{ $t('system.no_results') }}
                  </td>
                </tr>
              </tbody>
            </table>

          </div>

        </div>

      </b-form>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="task-modal-info" v-if="selectedTask && selectedTask.id">
            <div class="row">
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('projects.create.task_assignees') }}</strong>
                </div>
                <span class="mr-1 text-normal" v-if="selectedTask.users && selectedTask.users.length" v-for="user in getReducedUsers(selectedTask.users)">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
                <span v-if="selectedTask.users && selectedTask.users.length && selectedTask.users.length > 3"
                      class="badge cursor-pointer"
                      @click="openUsersModal(selectedTask)"
                >
                  <font-awesome-icon icon="users"/>
                  + {{ selectedTask.users.length - 3 }}
                </span>
              </div>
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('table.due_date') }}</strong>
                </div>
                <span class="text-normal">
                  <span v-if="getDifference(selectedTask.project.end_date) > 3">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-1"/>
                    {{ $t('system.due_in') }}
                    {{ getDifference(selectedTask.project.end_date) }}
                    {{ $t('system.days') }}
                  </span>
                  <span v-if="getDifference(selectedTask.project.end_date) <= 3 && getDifference(selectedTask.project.end_date) >= 0">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-6"/>
                    {{ $t('system.due_in') }}
                    {{ getDifference(selectedTask.project.end_date) }}
                    {{ $t('system.days') }}
                  </span>
                  <span v-if="getDifference(selectedTask.project.end_date) < 0">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-12"/>
                    {{ Math.abs(getDifference(selectedTask.project.end_date)) }}
                    {{ $t('system.days') }}
                    {{ $t('system.overdue') }}
                  </span>
                </span>
              </div>
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('system.task_status') }}</strong>
                </div>
                <span v-if="selectedTask.status === 'completed'" >
                  <font-awesome-icon icon="check-circle" class="icon-success"/>
                </span>
                <span v-if="selectedTask.status === 'pending'" class="pr-2">
                  <font-awesome-icon
                      icon="exclamation-triangle"
                      class="risk-6"
                  />
                </span>
                <span class="text-normal">
                  {{ $t('system.' + $options.filters.slugify(selectedTask.status)) }}
                </span>
              </div>
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('table.project') }}</strong>
                </div>
                <span v-if="isOwner(selectedTask.project)" class="text-success breadcrumb-item cursor-pointer" @click="navigateToProject(selectedTask)">
                  <font-awesome-icon icon="calendar-alt" class="mr-1"/>
                  {{ selectedTask.project.title }}
                </span>
                <span v-if="!isOwner(selectedTask.project)" class="text-success breadcrumb-item" style="pointer-events:none;">
                  <font-awesome-icon icon="calendar-alt" class="mr-1"/>
                  {{ selectedTask.project.title }}
                </span>
              </div>
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('projects.create.owners_label') }}</strong>
                </div>
                <span class="mr-1 text-normal" v-for="user in getReducedUsers(selectedTask.project.users)">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
                <span v-if="selectedTask.project.users.length > 3"
                      class="badge cursor-pointer"
                      @click="openUsersModal(selectedTask.project)"
                >
                  <font-awesome-icon icon="users"/>
                  + {{ selectedTask.project.users.length - 3 }}
                </span>
              </div>
            </div>
          </div>
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">
                <span>
                  <vm-progress
                      type="circle"
                      :percentage="project_progress"
                      style="vertical-align: middle;"
                      :width="50"
                      :stroke-width="2"
                      stroke-color="#00c29e"
                  >
                    {{project_progress}}%
                  </vm-progress>
                </span>
              </div>
              <div class="col-sm-6 text-right">
                <b-button class="ml-3 mt-2"
                          variant="success"
                          @click="completeTask()"
                          v-if="selectedTask.status != 'completed'"
                          :disabled="buttonDisabled"
                          qid="create-p-cancel-button"
                >
                  {{ $t('tasks.modal.complete') }}
                </b-button>
                <b-button class="ml-3 mt-2"
                          variant="success"
                          v-if="selectedTask.status != 'completed'"
                          @click="saveTask()"
                          :disabled="buttonDisabled"
                          qid="create-p-cancel-button"
                >
                  {{ $t('system.close') }}
                </b-button>
                <b-button class="ml-3 mt-2"
                          variant="success"
                          v-if="selectedTask.status == 'completed'"
                          @click="saveTask()"
                          :disabled="buttonDisabled"
                          qid="create-p-cancel-button"
                >
                  {{ $t('system.save') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>

      </template>
    </b-modal>


    <b-modal ref="all-done" hide-footer @hide="hideDoneModal" size="lg" modal-class="all-done-modal">

      <template #modal-header="{ close }">
        <div class="text-center pt-2 pb-2" style="width: 100%;">
          <span @click="hideDoneModal" class="btn btn-secondary btn-sm cursor-pointer float-right">
            <font-awesome-icon icon="times"/>
          </span>
          <h3>DONE!!!</h3>
        </div>
      </template>

      <img width="100%" src="@/assets/allDoneGif.gif" alt="">

    </b-modal>

    <!-- View validation modal template -->
    <b-modal ref="view-validation" hide-footer @hide="hideViewModal">
      <div class="d-block text-center">
        <h4 qid="delete-validation-title">
          {{ $t('validations.view.title') }}
        </h4>
      </div>
      <div class="d-block">
        <label qid="create-validation-status-label" class="mt-3">
          {{ $t('validations.create.status_label') }}
        </label>
        <br>
        <span v-if="itemToView.validation === 1" qid="validations-status-approved">
          <font-awesome-icon icon="check-circle" class="icon-success"/>
          {{ $t('system.approved') }}
        </span>
        <span v-if="itemToView.validation === 0">
          <span class="badge-pill badge-danger mr-2" qid="validations-status-rejected">
            <font-awesome-icon icon="times" size="xs"/>
          </span>
          {{ $t('system.rejected') }}
        </span>
        <br>
        <label qid="create-validation-comment-label" class="mt-4">
          {{ $t('validations.create.comment_label') }}
        </label>
        <br>
        {{ itemToView.comment }}
        <br>
        <b-form-group class="mt-2" v-if="!_.isEmpty(itemToView.checklist)">
          <label qid="create-validation-checklist-label" class="mt-3">
            {{ $t('validations.create.checklist_label') }}
          </label>
          <div class="row" v-if="itemToView.checklist">
            <div v-for="(value,key) in itemToView.checklist" class="col-sm-12">
              <p class="mt-2">
                {{ $t('validations.checklist.' + $options.filters.slugify(key)) }}
              </p>
              <div v-for="(itemValue,itemKey) in value" class="row">
                <div class="col-sm-6">
                  <p class="text-normal smaller">
                    {{ $t('validations.checklist.' + $options.filters.slugify(itemKey)) }}
                  </p>
                </div>
                <div class="col-sm-6 text-right">
                  {{ $t('system.' + $options.filters.slugify(itemValue)) }}
                </div>
              </div>
            </div>
          </div>
        </b-form-group>
        <b-form-group v-if="itemToView.media && itemToView.media.length">
          <label qid="create-validation-media-label" class="mt-3">
            {{ $t('validations.create.attachments_label') }}
          </label>
          <p v-for="item in itemToView.media">
            <span class="float-right text-muted cursor-pointer"
                  @click="downloadMedia(item)"
                  qid="download-media"
            >
              <font-awesome-icon icon="cloud-download-alt" />
            </span>
            <a @click="downloadMedia(item)" qid="download-media-button">
              {{ item.file_name }}
            </a>
          </p>
        </b-form-group>
        <b-form-group v-if="itemToView.links && itemToView.links.length">
          <label qid="create-validation-links-label" class="mt-3">
            {{ $t('validations.create.links_label') }}
          </label>
          <div v-if="itemToView.links">
            <p v-for="link in itemToView.links">
              <a :href="link.url" target="_blank" qid="create-tp-link-item">
                {{ link.name ? link.name : link.url }}
              </a>
            </p>
          </div>
        </b-form-group>
      </div>
      <div class="text-right">
        <b-button class="mt-3"
                  variant="success"
                  @click="hideViewModal"
                  qid="view-validation-cancel-button"
        >
          {{ $t('validations.view.close') }}
        </b-button>
      </div>
    </b-modal>


    <ConfirmRemoveAttachment
        :object="selectedFile"
        :show="showDeleteAttachmentModal"
        type="file"
        @closeConfirmModal="closeDeleteAttachmentModal"
    ></ConfirmRemoveAttachment>

    <ConfirmRemoveAttachment
        :object="selectedLink"
        :show="showDeleteLinkModal"
        type="link"
        @closeConfirmModal="closeDeleteLinkModal"
    ></ConfirmRemoveAttachment>

    <GuideViewModal
        :guide="selectedGuide"
        :showModal="viewGuideModal"
        @closeViewModal="closeViewGuideModal"
    ></GuideViewModal>

    <GuideWatchVideoModal
        :guide="selectedGuide"
        :showModal="viewWatchVideoModal"
        @closeWatchVideoModal="closeWatchVideoGuideModal"
    ></GuideWatchVideoModal>

    <ShowMoreModal
        :items="selectedItem.users"
        :show="showUsersModal"
        @closeViewModal="closeUsersModal"
        type="users"
    ></ShowMoreModal>

  </span>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Datepicker from 'vuejs-datepicker';
  import {config} from '@/config.js';
  import ConfirmRemoveAttachment from '@/components/ConfirmRemoveAttachment.vue';
  import GuideViewModal from '@/components/guide/GuideViewModal.vue';
  import GuideWatchVideoModal from '@/components/guide/GuideWatchVideoModal.vue';
  import Avatar from '@/components/Avatar.vue';
  import ShowMoreModal from '@/components/ShowMoreModal.vue';
  import roleCheck from "@/util/ACL/roleCheck"


  export default {
    name: 'ValidationTaskModal',
    data: function() {
      return {
        taskForm: {
          remarks: ''
        },
        selectedTask: [],
        taskList: [],
        selectedAnswer: [],
        allAnswers: [
          { name: this.$t('tasks.modal.pass'), value: 'pass' },
          { name: this.$t('tasks.modal.fail'), value: 'fail' }
        ],
        tableItems: [],
        perPage: 10,
        currentPage: 1,
        totalRows: 0,
        itemToDelete: {},
        itemToView: {},
        validationForm: {
          validation: '',
          comment: '',
          links: [],
          checklist: {}
        },
        validationOptions: [
          {text: this.$t('validations.create.status_accepted'), value: true},
          {text: this.$t('validations.create.status_rejected'), value: false},
        ],
        checklistOptions: [
          {text: this.$t('system.yes'), value: 'Yes'},
          {text: this.$t('system.no'), value: 'No'},
          {text: this.$t('system.n_a'), value: 'N/A'},
        ],
        selectedFiles: [],
        loading: true,
        buttonDisabled: false,
        showForm: false,
        disabledArray: [],
        checklist: null,
        selectedFile: {},
        selectedLink: {},
        showDeleteAttachmentModal: false,
        showDeleteLinkModal: false,
        showInput: false,
        showSingleLinkLabel: true,
        inputLink: '',
        inputLinkName: '',
        descriptionLength: config.descriptionLength,
        showFullProjectMessage: false,
        showFullGuideDescription: false,
        showFullGenericControlDescription: false,
        showFullGenericControlPassedDescription: false,
        showFullGenericControlFailedDescription: false,
        error: '',
        selectedGuide: {},
        viewGuideModal: false,
        viewWatchVideoModal: false,
        myGuides: [],
        submitForm: false,
        project_progress: 0,
        disabledValidationOptions: [],
        doNotMinimize: false,
        taskCompleted: false,
        welcomeGuide: {},
        wpGuide: {},
        selectedItem: {},
        showUsersModal: false
      }
    },
    props: {
      task: {},
      type: {},
      tasks: {},
      editIcon: {},
      activeGuide: {}
    },
    components: {
      Datepicker,
      ConfirmRemoveAttachment,
      GuideViewModal,
      GuideWatchVideoModal,
      Avatar,
      ShowMoreModal
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getUser() {
        return this.$store.state.user
      },
      getFiles() {
        return this.selectedFiles
      },
      getWordpressLink() {
        return this.$store.state.wordpressLink
      },
    },
    watch: {
      getClient: function () {

      }
    },
    methods: {
      openGuide() {
        if (this.wpGuide && this.wpGuide.link) {
          window.open(this.wpGuide.link, '_blank')
        }
      },
      isOwner(object) {
        let self = this;
        let allow = false;
        let existingUser = _.find(object.users, function(user) {
          return user.id == self.getUser.id
        });

        if (existingUser) {
          allow = true
        } else {
          if (roleCheck('Client admin') || roleCheck('Partition admin') || this.getUser.isAdmin) {
            allow = true
          }
        }

        return allow
      },
      getDifference(endDate) {
        var moment = require('moment');
        let currentDate = moment()
        endDate = moment(endDate);
        let difference = endDate.diff(currentDate, 'days');
        return difference;
      },
      getReducedUsers(users) {
        let reducedUsers = []
        if (users) {
          if (users.length > 3) {
            reducedUsers = _.cloneDeep(users);
            reducedUsers = reducedUsers.slice(0, 3);
          } else {
            reducedUsers = users;
          }
        }
        return reducedUsers;
      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      showTaskModal(object) {

        let self = this;
        this.error = '';

        if (object != null) {
          self.selectedTask = object
        }

        if (object == null && this.tasks && this.tasks.length) {
          self.selectedTask = this.tasks[0]
        }

        this.loadTaskById(this.selectedTask.id, true);
        this.loadGuides(this.selectedTask.code)
        this.loadValidations()

        this.$refs['task-modal'].show()
        this.loadTaskGuide()
      },
      openWelcomePage() {
        this.showViewGuideModal(this.welcomeGuide)
      },
      loadTaskGuide() {
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';
        let self = this;
        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Task',
            scope: 'validation_task',
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.welcomeGuide = response.data.items[0]
          }
        }, function() {});
      },
      navigateToTask() {
        this.$store.commit('storeTabIndex', '');
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', this.selectedTask);

        if (this.selectedTask.model.who_am_i == 'App\\Activity\\Models\\Activity') {
          this.$router.push({ name: 'activities.view', params: { slug: this.selectedTask.model.slug }, query: { task: this.selectedTask.id }})
        }
        if (this.selectedTask.model.who_am_i == 'App\\Process\\Models\\Process') {
          this.$router.push({ name: 'processes.view', params: { slug: this.selectedTask.model.slug }, query: { task: this.selectedTask.id }})
        }
        if (this.selectedTask.model.who_am_i == 'App\\System\\Models\\System') {
          this.$router.push({ name: 'systems.view', params: { slug: this.selectedTask.model.slug }, query: { task: this.selectedTask.id }})
        }
        if (this.selectedTask.model.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty') {
          this.$router.push({ name: 'contracts.view', params: { slug: this.selectedTask.model.slug }, query: { task: this.selectedTask.id }})
        }
        if (this.selectedTask.model.who_am_i == 'App\\Maintenance\\Policy\\Models\\Policy') {
          this.$router.push({ name: 'policies.view', params: { slug: this.selectedTask.model.slug, }, query: { task: this.selectedTask.id }})
        }

      },
      updateTasks(model) {
        _.forEach(this.taskList, function (item) {
          if (item.model.id == model.id) {
            item.model = model;
          }
        })
      },
      minimizeTask() {
        var element = document.getElementsByClassName('modal');
        element[0].classList.add("bottom-left");
        // this.submitForm = true
        this.$store.commit('storeActiveTask', this.selectedTask);
        this.$store.commit('storeTaskToolbarState', true);
      },
      hideTaskModal() {
        let self = this;
        this.error = '';

        setTimeout(function(){
          self.$emit('reloadContent');
          self.selectedTask = {}
        }, 500);

        if (!this.taskCompleted && this.selectedTask.status != 'completed') {
          this.minimizeTask();
        }

        this.$refs['task-modal'].hide()

      },
      createValidationFormSubmit(evt) {
        let formData = new FormData();
        let files = document.querySelector('#file');
        let sizeError = false;

        // Call handle link function to gather link from link input
        this.handleLink()

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        if (evt) {
          evt.preventDefault();
        }

        // Append list of attachments to FormData
        for (var i = 0; i < files.files.length; i++) {
          // Equal to 10 MB
        if (files.files[i].size > 10485760) {
          sizeError = true;
        }
          formData.append("files[]", files.files[i]);
        }

        // Append list of links to FormData
        for (var i = 0; i < this.validationForm.links.length; i++) {
          formData.append("links[]", JSON.stringify(this.validationForm.links[i]));
        }

        formData.append("validation", this.validationForm.validation.value ? 1 : 0)
        formData.append("comment", this.validationForm.comment)


        if (this.validationForm.checklist) {
          formData.append("checklist", JSON.stringify(this.validationForm.checklist))
        }
        formData.append("type", this.getValidationType(this.selectedTask.code))
        formData.append("model_type", this.selectedTask.model.who_am_i)
        formData.append("model_id", this.selectedTask.model.id)

        if (sizeError) {
          this.error = this.$t('system.file_size')
        } else {
          this.buttonDisabled = true;
          piincHttp.post('validations', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function() {
            self.hideValidationForm();
            self.loadValidations()
            self.buttonDisabled = false;
            self.$toastr('success', self.$t('validations.create.success'))
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
              if (error.data.errors['files.0']) {
                self.error += ' ' + error.data.errors['files.0'][0]
              }
            }
          })
        }
      },
      showValidationForm(object) {
        this.validationForm.validation = '';
        this.validationForm.comment = '';
        this.checklist = null


        //////
        // Here set the checklist depending on type
        //this.validationOptions = this.activity.integrityValidationOptions;

        if (this.selectedTask.code == 'validate_integrity_check') {
          this.checklist = this.selectedTask.model.integrityValidationOptions
        }
        if (this.selectedTask.code == 'validate_processing_type') {
          this.checklist = this.selectedTask.model.processingTypeValidationOptions
        }
        if (this.selectedTask.code == 'validate_information_obligation') {
          this.checklist = this.selectedTask.model.information_obligation_validation_options
        }
        if (this.selectedTask.code == 'validate_legal_basis') {
          this.checklist = this.selectedTask.model.legal_basis_validation_options
        }
        if (this.selectedTask.code == 'validate_access_control') {
          this.checklist = this.selectedTask.model.accessControlValidationOptions
          this.disabledArray = []
          if (this.selectedTask.model.accessControlsValidation.provisioning == false) {
            this.disabledArray.push('Provisioning')
          }
          if (this.selectedTask.model.accessControlsValidation.re_certification == false) {
            this.disabledArray.push('Re-certification')
          }
        }
        if (this.selectedTask.code == 'validate_data_discovery') {
          this.checklist = this.selectedTask.model.dataDiscoveryValidationOptions
          this.disabledArray = [];
          if (this.selectedTask.model.dataDiscoveriesValidation.data_scanning == false) {
            this.disabledArray.push('Data scanning')
          }
        }
        if (this.selectedTask.code == 'validate_log_management') {
          this.checklist = this.selectedTask.model.logManagementValidationOptions
          this.disabledArray = [];
          if (this.selectedTask.model.logManagementsValidation.log_analysis == false) {
            this.disabledArray.push('Log analysis')
          }
          if (this.selectedTask.model.logManagementsValidation.log_collection == false) {
            this.disabledArray.push('Log collection')
          }
        }
        if (this.selectedTask.code == 'validate_network') {
          this.checklist = this.selectedTask.model.networkValidationOptions
          this.disabledArray = [];
          if (this.selectedTask.model.networksValidation.exposed_to_open_networks == false) {
            this.disabledArray.push('Exposed to open networks')
          }
        }
        if (this.selectedTask.code == 'validate_third_party') {
          this.checklist = this.selectedTask.model.validationOptions
        }


        if (this.checklist) {
          // Set all checklist sections to empty objects
          // We need this so we can later dynamically assign models in multidimensional array
          for (var option in this.checklist) {
            this.validationForm.checklist[option] = {}
          }
        }
        this.validationForm.links = [];
        this.error = '';
        this.selectedFiles = [];
        this.showForm = true;
      },
      hideValidationForm() {
        this.validationForm.checklist = {}
        this.showForm = false;
      },
      saveTask(model) {
        this.hideTaskModal()
      },
      showViewGuideModal(object) {
        this.selectedGuide = object
        this.viewGuideModal = true
      },
      closeViewGuideModal() {
        this.selectedGuide = ''
        this.viewGuideModal = false
      },
      showWatchVideoGuideModal(object) {
        this.selectedGuide = object
        this.viewWatchVideoModal = true
      },
      closeWatchVideoGuideModal() {
        this.selectedGuide = ''
        this.viewWatchVideoModal = false
      },
      completeTask() {
        this.buttonDisabled = true;

        if (this.showForm) {
          this.createValidationFormSubmit()
        }

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        this.error = ''

        piincHttp.put('tasks/' + this.selectedTask.id, {
          status: 'completed'
        }).then(function(response) {
          self.buttonDisabled = false;

          if (self.taskList.length > 1) {
            let index = _.findIndex(self.taskList, function(o) { return o.id == self.selectedTask.id })
            if (index < self.taskList.length - 1) {
              self.selectedTask = self.taskList[index + 1]
            } else {
              self.selectedTask = self.taskList[0]
            }
            self.taskList.splice(index, 1);
            self.loadTaskById(self.selectedTask.id);
          } else {
            self.taskCompleted = true;
            self.$store.commit('storeTaskToolbarState', false);
            if (self.selectedTask.code == 'update_basic_info') {
              self.$store.commit('storeUpdatePage', true);
            }
            self.hideTaskModal()
          }
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })

      },
      hideDoneModal() {
        this.$refs['all-done'].hide()
        this.doNotMinimize = true
        this.$refs['task-modal'].hide()
        this.$emit('reloadContent');
        this.$store.commit('storeTaskToolbarState', false);
        this.$store.commit('storeActiveTask', {});
      },
      previousTask() {
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        this.error = ''

        let index = _.findIndex(self.taskList, function(o) { return o.id == self.selectedTask.id })
        if (index > 0) {
          this.selectedTask = this.taskList[index - 1]
        } else {
          this.selectedTask = this.taskList[this.taskList.length - 1]
        }

        self.loadTaskById(this.selectedTask.id);

      },
      nextTask() {
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        this.error = ''

        let index = _.findIndex(self.taskList, function(o) { return o.id == self.selectedTask.id })
        if (index < this.taskList.length - 1) {
          this.selectedTask = this.taskList[index + 1]
        } else {
          this.selectedTask = this.taskList[0]
        }

        self.loadTaskById(self.selectedTask.id);

      },
      resetFile() {
        let imagefile = document.querySelector('#file');
        imagefile.value = '';
      },
      loadTaskById(id, ignorePopulate) {
        this.hideValidationForm()
        let self = this;
        piincHttp.get('tasks/' + id).then(function(response) {
          self.selectedTask = response.data;
          self.project_progress = self.selectedTask.project.user_progress.progress;
          self.loadGuides(self.selectedTask.code)
          self.loadValidations()

        }, function() {});
      },
      openDeleteAttachmentModal(object) {
        this.selectedFile = object
        this.showDeleteAttachmentModal = true
      },
      closeDeleteAttachmentModal () {
        this.selectedFile = {}
        this.showDeleteAttachmentModal = false;
        this.loadTaskById(this.selectedTask.id, true);
      },
      openDeleteLinkModal(object) {
        this.selectedLink = object
        this.showDeleteLinkModal = true
      },
      closeDeleteLinkModal () {
        this.selectedLink = {}
        this.showDeleteLinkModal = false;
        this.loadTaskById(this.selectedTask.id, true);
      },
      downloadMedia(object){
        piincHttp.get('documents/' + object.id + '/download', {
          responseType: 'blob',
          params:
            {
              client: this.getClient.slug,
              task: this.selectedTask.id
            }
        }).then(function(response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = object.file_name;

          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);
        }, function() {});
      },
      downloadScopeMedia(object){
        piincHttp.get('tasks/'+this.selectedTask.id+'/documents/' + object.id + '/download', {
          responseType: 'blob',
          params:
            {
              client: this.getClient.slug
            }
        }).then(function(response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = object.file_name;

          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);
        }, function() {});
      },
      getValidationType(code) {
        let type = ''

        if (code == 'validate_legal_basis') {
          type = 'legal_basis'
        }
        if (code == 'validate_information_obligation') {
          type = 'information_obligation'
        }
        if (code == 'validate_risk_profile') {
          type = 'risk_profile'
        }
        if (code == 'validate_confidentiality_impact_and_probability') {
          type = 'confidentiality'
        }
        if (code == 'validate_integrity_impact_and_probability') {
          type = 'integrity'
        }
        if (code == 'validate_availability_impact_and_probability') {
          type = 'availability'
        }
        if (code == 'validate_risk_scenarios') {
          type = 'risk_scenario'
        }

        if (code == 'validate_access_control') {
          type = 'access_control'
        }
        if (code == 'validate_log_management') {
          type = 'log_management'
        }
        if (code == 'validate_network') {
          type = 'network'
        }
        if (code == 'validate_data_discovery') {
          type = 'data_discovery'
        }

        if (code == 'validate_retention_rule') {
          type = 'retention_rule'
        }
        if (code == 'validate_integrity_check') {
          type = 'integrity_check'
        }
        if (code == 'validate_processing_type') {
          type = 'processing_type'
        }
        if (code == 'validate_mapping_description') {
          type = 'mapping_description'
        }
        if (code == 'validate_third_party') {
          type = 'third_party'
        }
        if (code == 'validate_measures') {
          type = 'measure'
        }
        if (code == 'validate_policy') {
          type = 'policy'
        }

        return type
      },
      loadValidations(page) {

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let self = this;
        piincHttp.get('validations', { params:
          {
            page: pageNumber,
            model_type: this.selectedTask.model.who_am_i,
            model_id: this.selectedTask.model.id,
            type: this.getValidationType(this.selectedTask.code),
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.loading = false;
          self.tableItems = response.data.items;
          // Set number of total items
          self.totalRows = response.data.total_count;
        }, function() {});
      },
      onFileChange() {
        let files = document.querySelector('#file');
        // in order for v-for to re render, we need to set array to empty
        this.selectedFiles = [];
        // And then push item by item into that array
        for (var i = 0; i < files.files.length; i++) {
          this.selectedFiles.push(files.files[i])
        }
      },
      handleLink() {
        // If there's a link in input, add it to links array
        if (this.inputLink !== '') {
          if (!this.inputLink.startsWith('http')) {
            this.inputLink = 'http://' + this.inputLink
          }
          this.validationForm.links.push({url: this.inputLink, name: this.inputLinkName})
          // Reset link input form
          this.inputLink = ''
          this.inputLinkName = ''
        }
      },
      removeLinkItem(index) {
        // Remove link item from the links array
        this.validationForm.links.splice(index, 1);
      },
      pageChanged(page) {
        // Load validation when page changed listener is triggered
        this.loadValidations(page)
      },
      showViewModal(object) {
        this.itemToView = object
        if (object.checklist) {
          // Try to create object from string, if checklist is string
          try {
            this.itemToView.checklist = JSON.parse(object.checklist)
          } catch (e) {}
        }
        // If already object, just proceed to open the modal
        this.$refs['view-validation'].show()
      },
      hideViewModal() {
        this.itemToView = {};
        this.$refs['view-validation'].hide()
      },
      getUserAvatar(user){
        if (user && user.avatar) {
          return user.avatar;
        } else {
          return require('@/assets/avatar.png');
        }
      },
      navigateToProject(task) {
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', task);
        this.$router.push({ name: 'projects.view', params: { slug: task.project.slug }})
      },
      getGuide(code) {
        let existingGuide = {}
        if (this.selectedTask && this.selectedTask.model && this.selectedTask.model.who_am_i == 'App\\Process\\Models\\Process') {
          existingGuide = _.find(this.myGuides, function(object) {
            return object.scope == code && object.type == 'Process' && object.enabled
          });
        }
        if (this.selectedTask && this.selectedTask.model && this.selectedTask.model.who_am_i == 'App\\System\\Models\\System') {
          existingGuide = _.find(this.myGuides, function(object) {
            return object.scope == code && object.type == 'System' && object.enabled
          });
        }
        if (this.selectedTask && this.selectedTask.model && this.selectedTask.model.who_am_i == 'App\\Activity\\Models\\Activity') {
          existingGuide = _.find(this.myGuides, function(object) {
            return object.scope == code && object.type == 'Activity' && object.enabled
          });
        }
        if (this.selectedTask && this.selectedTask.model && this.selectedTask.model.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty') {
          existingGuide = _.find(this.myGuides, function(object) {
            return object.scope == code && object.type == 'Third party' && object.enabled
          });
        }

        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        let type = ''

        if (this.selectedTask && this.selectedTask.model && this.selectedTask.model.who_am_i == 'App\\Process\\Models\\Process') {
          type = 'Process'
        }
        if (this.selectedTask && this.selectedTask.model && this.selectedTask.model.who_am_i == 'App\\System\\Models\\System') {
          type = 'System'
        }
        if (this.selectedTask && this.selectedTask.model && this.selectedTask.model.who_am_i == 'App\\Activity\\Models\\Activity') {
          type = 'Activity'
        }
        if (this.selectedTask && this.selectedTask.model && this.selectedTask.model.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty') {
          type = 'Third party'
        }

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: type,
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
            piincHttp.get(self.getWordpressLink + self.myGuides[0].external_id).then(function(response) {
              self.wpGuide = response.data
            }, function() {});
          }
        }, function() {});
      },
    },
    created () {

    }
  }
</script>
