<template>
  <span>
    <b-button
              variant="edit"
              @click="showTaskModal(task)"
              qid="delete-s-cancel-button"
    >
    <span v-if="task">
      <font-awesome-icon icon="pen"/>
    </span>
    <span v-if="!task">
      {{ $t('tasks.open_first_task') }}
    </span>
    </b-button>

    <!-- Task modal -->
    <b-modal ref="task-modal" @hide="hideTaskModal" size="xl" modal-class="bottom-left task-modal">

      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideTaskModal" class="btn btn-secondary cursor-pointer float-right">
            <font-awesome-icon icon="window-minimize"/>
          </span>
          <h5 class="mb-1 text-center" v-if="selectedTask && selectedTask.id && selectedTask.model">
            {{ $t('tasks.control_tasks_title') }}
            <span class="breadcrumb-item" v-if="selectedTask && selectedTask.id && selectedTask.scope">
              <a @click="navigateToControl(selectedTask)" :class="{'not-clickable': !isOwner(selectedTask.scope)}">
                <font-awesome-icon icon="shield-alt" class="mr-1"/>
                {{ selectedTask.scope.name }}
              </a>
            </span>
            <span v-if="selectedTask.code != 'security_measure'">
              {{ $t('system.on') }}
              <span v-if="selectedTask.model.who_am_i == 'App\\System\\Models\\System'">
                {{ $t('system.the_system') }}
              </span>
              <span v-if="selectedTask.model.who_am_i == 'App\\Activity\\Models\\Activity'">
                {{ $t('system.the_activity') }}
              </span>
              <span v-if="selectedTask.model.who_am_i == 'App\\Process\\Models\\Process'">
                {{ $t('system.the_process') }}
              </span>
              <span v-if="selectedTask.model.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'">
                {{ $t('system.the_contract') }}
              </span>
              <span v-if="selectedTask.model.who_am_i == 'App\\Maintenance\\Policy\\Models\\Policy'">
                {{ $t('system.the_policy') }}
              </span>
              <span class="breadcrumb-item ml-2">
                <a @click="navigateToTask(selectedTask)" :class="{'not-clickable': !isOwner(selectedTask.model)}">
                  <font-awesome-icon icon="desktop" class="mr-1" v-if="selectedTask.model.who_am_i == 'App\\System\\Models\\System'"/>
                  <font-awesome-icon icon="cubes" class="mr-1" v-if="selectedTask.model.who_am_i == 'App\\Activity\\Models\\Activity'"/>
                  <font-awesome-icon icon="cube" class="mr-1" v-if="selectedTask.model.who_am_i == 'App\\Process\\Models\\Process'"/>
                  <font-awesome-icon icon="file-signature" class="mr-1" v-if="selectedTask.model.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'"/>
                  <font-awesome-icon icon="file-invoice" class="mr-1" v-if="selectedTask.model.who_am_i == 'App\\Maintenance\\Policy\\Models\\Policy'"/>

                  {{ selectedTask.model.name }}
                </a>
              </span>
              <span v-if="selectedTask.model.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'">
                {{ $t('system.with') }}
                <Avatar :object="selectedTask.model.vendors[0]" size="xs"></Avatar>
                <span class="ml-1">{{ selectedTask.model.vendors[0].name }}</span>
              </span>
            </span>
          </h5>
          <div class="row mt-3 pt-4 pb-1 pl-5 pr-5" v-if="selectedTask && selectedTask.id">

            <div :class="{'col-sm-6': selectedTask.scope && selectedTask.scope.name, 'col-sm-12': !selectedTask.scope}">
              <b-form-group>
                <div class="">
                  <span class="btn btn-secondary btn-xs float-right cursor-pointer" @click="openWelcomePage()" v-if="welcomeGuide && welcomeGuide.id">
                    <font-awesome-icon icon="eye" />
                    {{ $t('system.welcome_page_button') }}
                  </span>
                  <div v-if="getGuide('control_task')">
                    <span class="btn btn-secondary btn-xs float-right cursor-pointer" @click="openGuide()">
                      <font-awesome-icon icon="eye" />
                      {{ $t('administration.guide.modal.button') }}
                    </span>

                    <label qid="create-p-users-label" v-if="selectedTask.project && selectedTask.project.schedule_message">
                      {{ $t('tasks.modal.project_message_label') }}
                    </label>
                    <div class="" v-if="selectedTask.project && selectedTask.project.schedule_message">
                      <p>
                        {{ selectedTask.project.schedule_message }}
                      </p>
                    </div>

                    <label qid="create-p-users-label">
                      {{ $t('system.help_center_label') }}
                    </label>
                    <div v-if="wpGuide && wpGuide.id" class="guide-content-container">
                      <p v-if="!showFullGuideDescription" v-html="wpGuide.excerpt.rendered"></p>
                      <p v-if="showFullGuideDescription" v-html="wpGuide.content.rendered"></p>

                      <div class="text-center mt-3" @click="showFullGuideDescription=!showFullGuideDescription">
                        <span class="cursor-pointer" v-if="!showFullGuideDescription">
                          <span class="mr-2">
                            {{ $t('system.view_more') }}
                          </span>
                          <font-awesome-icon icon="chevron-down"/>
                        </span>
                        <span class="cursor-pointer" v-if="showFullGuideDescription">
                          <span class="mr-2">
                            {{ $t('system.view_less') }}
                          </span>
                          <font-awesome-icon icon="chevron-up"/>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-1" v-if="selectedTask.project.media">
                  <p v-for="file in selectedTask.project.media">
                    <span class="mr-3 float-right text-muted cursor-pointer"
                          @click="downloadMedia(file)"
                          qid="download-media"
                    >
                      <font-awesome-icon icon="cloud-download-alt" />
                    </span>
                    <a @click="downloadMedia(file)" qid="create-p-media-item">
                      {{ file.file_name }}
                    </a>
                  </p>
                </div>

                <div class="" v-if="selectedTask.project.links">
                  <p v-for="link in selectedTask.project.links" >
                    <a :href="link.url" target="_blank" qid="create-p-link-item">
                      {{ link.name ? link.name : link.url }}
                    </a>
                  </p>
                </div>
              </b-form-group>
            </div>

            <div class="col-sm-6"
                 v-if="selectedTask.scope && selectedTask.scope.name"
            >
              <b-form-group>
                <div class="">
                  <!-- <span class="btn btn-secondary btn-xs float-right cursor-pointer" @click="showViewGuideModal(getGuide(selectedTask.code))">
                    <font-awesome-icon icon="eye" />
                    {{ $t('administration.guide.modal.button') }}
                  </span> -->
                  <label qid="create-p-users-label">
                    <font-awesome-icon icon="shield-alt" class="mr-2"/>
                    {{ selectedTask.scope.name }}
                  </label>
                  <div v-if="selectedTask.scope.description && selectedTask.scope.description.length > 300">
                    <p v-if="showFullGenericControlDescription" class="text-formatted" v-html="selectedTask.scope.description">
                    </p>
                    <p v-if="!showFullGenericControlDescription" class="text-formatted" v-html="$options.filters.truncate(selectedTask.scope.description, 300)">
                    </p>
                    <div class="text-center mt-3" @click="showFullGenericControlDescription=!showFullGenericControlDescription">
                      <span class="cursor-pointer" v-if="!showFullGenericControlDescription">
                        <span class="mr-2">
                          {{ $t('system.view_more') }}
                        </span>
                        <font-awesome-icon icon="chevron-down"/>
                      </span>
                      <span class="cursor-pointer" v-if="showFullGenericControlDescription">
                        <span class="mr-2">
                          {{ $t('system.view_less') }}
                        </span>
                        <font-awesome-icon icon="chevron-up"/>
                      </span>
                    </div>
                  </div>
                  <p v-if="selectedTask.scope.description && selectedTask.scope.description.length <= 300"
                     class="text-formatted"
                     v-html="selectedTask.scope.description"
                  >
                  </p>
                </div>
                <div class="mt-2 mb-1" v-if="selectedTask.scope.media">
                  <p v-for="file in selectedTask.scope.media">
                    <span class="mr-3 float-right text-muted cursor-pointer"
                          @click="downloadScopeMedia(file)"
                          qid="download-media"
                    >
                      <font-awesome-icon icon="cloud-download-alt" />
                    </span>
                    <a @click="downloadScopeMedia(file)" qid="create-p-media-item">
                      {{ file.file_name }}
                    </a>
                  </p>
                </div>

                <div class="" v-if="selectedTask.scope.links">
                  <p v-for="link in selectedTask.scope.links" >
                    <a :href="link.url" target="_blank" qid="create-p-link-item">
                      {{ link.name ? link.name : link.url }}
                    </a>
                  </p>
                </div>
              </b-form-group>
            </div>

          </div>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>

      <b-alert v-if="!selectedTask.scope"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ $t('tasks.generic_control_not_available') }}
      </b-alert>

      <b-form qid="create-p-form" v-if="selectedTask && selectedTask.model">
        <div class="form-container mt-0 mb-2">

          <div class="row mt-1" v-if="selectedTask && selectedTask.scope">

            <div class="col-sm-6">
              <b-form-group>
                <label qid="create-s-tags-label">
                  {{ $t('tasks.modal.response_label') }}
                  <span class="text-red">*</span>
                </label>
                <multiselect
                    v-model="selectedAnswer"
                    :options="allAnswers"
                    :multiple="false"
                    :taggable="false"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    :placeholder="$t('tasks.modal.response_placeholder')"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <font-awesome-icon icon="times-circle" class="icon-danger" v-if="props.option.value == 'fail'" style="background:white;border-radius:50%;"/>
                    <font-awesome-icon icon="check-circle" class="icon-success" v-if="props.option.value == 'pass'" style="background:white;border-radius:50%;"/>
                    <span class="ml-2">
                      {{ props.option.name }}
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <font-awesome-icon icon="times-circle" class="icon-danger" v-if="props.option.value == 'fail'" style="background:white;border-radius:50%;"/>
                    <font-awesome-icon icon="check-circle" class="icon-success" v-if="props.option.value == 'pass'" style="background:white;border-radius:50%;"/>
                    <span class="ml-2">
                      {{ props.option.name }}
                    </span>
                  </template>
                </multiselect>
              </b-form-group>
              <b-form-group v-if="selectedTask && (selectedTask.add_description == null || selectedTask.add_description != 'No')">
                <label qid="create-p-users-label">
                  {{ $t('tasks.modal.remarks_label') }}
                  <span class="text-red" v-if="selectedTask && selectedTask.add_description == 'Mandatory'">*</span>
                </label>
                <b-form-textarea
                  qid="create-s-form-description"
                  v-model="taskForm.remarks"
                  rows="6"
                  max-rows="6"
                  :required="selectedTask.add_description == 'Mandatory'"
                  :placeholder="$t('tasks.modal.remarks_placeholder')"
                ></b-form-textarea>
              </b-form-group>
              <div class="mt-2" v-if="selectedTask && (selectedTask.add_attachments == null || selectedTask.add_attachments != 'No')">
                <label>
                  {{ $t('maintenance.policies.create.attachments') }}
                  <span class="text-red" v-if="selectedTask && selectedTask.add_attachments == 'Mandatory'">*</span>
                  &nbsp;
                  <span class="text-muted">({{ $t('attachments.file_size_label') }})</span>
                </label>
                <br>
                <p v-for="file in selectedTask.media">
                  <span class="float-right btn btn-link btn-inline-icons cursor-pointer"
                        @click="openDeleteAttachmentModal(file)"
                        qid="remove-media-item"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </span>
                  <span class="mr-3 float-right text-muted cursor-pointer"
                        @click="downloadMedia(file)"
                        qid="download-media"
                  >
                    <font-awesome-icon icon="cloud-download-alt" />
                  </span>
                  <a href="#" @click="downloadMedia(file)" qid="create-p-media-item">
                    {{ file.file_name }}
                  </a>
                </p>
                <label class="btn btn-secondary cursor-pointer"
                       qid="create-p-file-button"
                >
                  <input type="file"
                         name="fileupload"
                         value="fileupload"
                         multiple
                         id="file"
                         @change="onFileChange"
                         @click="resetFile"
                  >
                  <span>
                    <font-awesome-icon icon="cloud-upload-alt" />
                    {{ $t('system.upload') }}
                  </span>
                </label>
                <br>
              </div>
              <div class="mt-4" v-if="selectedTask && (selectedTask.add_attachments == null || selectedTask.add_attachments != 'No')">
                <label>
                  {{ $t('maintenance.policies.create.links_label') }}
                  <span class="text-red" v-if="selectedTask && selectedTask.add_attachments == 'Mandatory'">*</span>
                </label>
                <div v-if="selectedTask.links">
                  <p v-for="link in selectedTask.links" >
                    <span class="float-right btn btn-link btn-inline-icons cursor-pointer"
                          @click="openDeleteLinkModal(link)"
                          qid="view-p-remove-link"
                    >
                      <font-awesome-icon icon="trash-alt" />
                    </span>
                    <a :href="link.url" target="_blank" qid="create-p-link-item">
                      {{ link.name ? link.name : link.url }}
                    </a>
                  </p>
                </div>
                <div v-if="showInput">
                  <label class="mt-2">{{ $t('system.link') }}</label>
                  <b-form-input
                    type="text"
                    aria-describedby="inputGroup"
                    v-model="inputLink"
                    :placeholder="$t('maintenance.third_parties.create.links_placeholder')"
                    qid="create-p-links"
                  ></b-form-input>
                  <label class="mt-2">{{ $t('table.name') }}</label>
                  <b-form-input
                    type="text"
                    aria-describedby="inputGroup"
                    v-model="inputLinkName"
                    :placeholder="inputLink ? inputLink : $t('table.name')"
                    qid="create-p-links"
                  ></b-form-input>
                  <div class="text-right mt-2">
                    <span class="btn btn-success cursor-pointer mr-0"
                          id="inputGroup"
                          @click="handleLink"
                          qid="handle-link-save"
                    >
                      {{ $t('system.save') }}
                    </span>
                  </div>
                </div>
                <span class="btn btn-link cursor-pointer"
                      @click="showInput = true"
                      qid="add-another-link"
                >
                  <font-awesome-icon icon="plus"/> {{ $t('system.add_link') }}
                </span>
                <br>
              </div>

            </div>

            <div class="col-sm-6">

              <b-form-group v-if="selectedTask && selectedTask.scope && selectedTask.scope.passed_description && selectedTask.scope.passed_description.length" class="mb-4 mt-4">
                <label qid="create-p-users-label" class="mb-0">
                  <font-awesome-icon icon="check-circle" class="icon-success"/>
                  {{ $t('tasks.modal.generic_control_passed_description_label') }}
                </label>
                <div v-if="selectedTask.scope.passed_description && selectedTask.scope.passed_description.length > 300">
                  <p v-if="showFullGenericControlPassedDescription" class="text-formatted">
                    {{ selectedTask.scope.passed_description }}
                  </p>
                  <p v-if="!showFullGenericControlPassedDescription" class="text-formatted">
                    {{ selectedTask.scope.passed_description | truncate(300) }}
                  </p>
                  <div class="text-center mt-3" @click="showFullGenericControlPassedDescription=!showFullGenericControlPassedDescription">
                    <span class="cursor-pointer" v-if="!showFullGenericControlPassedDescription">
                      <span class="mr-2">
                        {{ $t('system.view_more') }}
                      </span>
                      <font-awesome-icon icon="chevron-down"/>
                    </span>
                    <span class="cursor-pointer" v-if="showFullGenericControlPassedDescription">
                      <span class="mr-2">
                        {{ $t('system.view_less') }}
                      </span>
                      <font-awesome-icon icon="chevron-up"/>
                    </span>
                  </div>
                </div>
                <p v-if="selectedTask.scope.passed_description && selectedTask.scope.passed_description.length <= 300"
                   class="text-formatted"
                >
                  {{ selectedTask.scope.passed_description }}
                </p>
              </b-form-group>
              <b-form-group v-if="selectedTask && selectedTask.scope && selectedTask.scope.failed_description && selectedTask.scope.failed_description.length">
                <label qid="create-p-users-label" class="mb-0">
                  <font-awesome-icon icon="times-circle" class="icon-danger"/>
                  {{ $t('tasks.modal.generic_control_failed_description_label') }}
                </label>
                <div v-if="selectedTask.scope.failed_description && selectedTask.scope.failed_description.length > 300">
                  <p v-if="showFullGenericControlFailedDescription" class="text-formatted">
                    {{ selectedTask.scope.failed_description }}
                  </p>
                  <p v-if="!showFullGenericControlFailedDescription" class="text-formatted">
                    {{ selectedTask.scope.failed_description | truncate(300) }}
                  </p>
                  <div class="text-center mt-3" @click="showFullGenericControlFailedDescription=!showFullGenericControlFailedDescription">
                    <span class="cursor-pointer" v-if="!showFullGenericControlFailedDescription">
                      <span class="mr-2">
                        {{ $t('system.view_more') }}
                      </span>
                      <font-awesome-icon icon="chevron-down"/>
                    </span>
                    <span class="cursor-pointer" v-if="showFullGenericControlFailedDescription">
                      <span class="mr-2">
                        {{ $t('system.view_less') }}
                      </span>
                      <font-awesome-icon icon="chevron-up"/>
                    </span>
                  </div>
                </div>
                <p v-if="selectedTask.scope.failed_description && selectedTask.scope.failed_description.length <= 300"
                   class="text-formatted"
                >
                  {{ selectedTask.scope.failed_description }}
                </p>
              </b-form-group>
            </div>

          </div>

        </div>

      </b-form>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="task-modal-info" v-if="selectedTask && selectedTask.id">
            <div class="row">
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('projects.create.task_assignees') }}</strong>
                </div>
                <span class="mr-1 text-normal" v-if="selectedTask.users && selectedTask.users.length" v-for="user in getReducedUsers(selectedTask.users)">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
                <span v-if="selectedTask.users && selectedTask.users.length && selectedTask.users.length > 3"
                      class="badge cursor-pointer"
                      @click="openUsersModal(selectedTask)"
                >
                  <font-awesome-icon icon="users"/>
                  + {{ selectedTask.users.length - 3 }}
                </span>
              </div>
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('table.due_date') }}</strong>
                </div>
                <span class="text-normal">
                  <span v-if="getDifference(selectedTask.project.end_date) > 3">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-1"/>
                    {{ $t('system.due_in') }}
                    {{ getDifference(selectedTask.project.end_date) }}
                    {{ $t('system.days') }}
                  </span>
                  <span v-if="getDifference(selectedTask.project.end_date) <= 3 && getDifference(selectedTask.project.end_date) >= 0">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-6"/>
                    {{ $t('system.due_in') }}
                    {{ getDifference(selectedTask.project.end_date) }}
                    {{ $t('system.days') }}
                  </span>
                  <span v-if="getDifference(selectedTask.project.end_date) < 0">
                    <font-awesome-icon icon="exclamation-triangle" class="risk-12"/>
                    {{ Math.abs(getDifference(selectedTask.project.end_date)) }}
                    {{ $t('system.days') }}
                    {{ $t('system.overdue') }}
                  </span>
                </span>
              </div>
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('system.task_status') }}</strong>
                </div>
                <span v-if="selectedTask.status === 'completed'" >
                  <font-awesome-icon icon="check-circle" class="icon-success"/>
                </span>
                <span v-if="selectedTask.status === 'pending'" class="pr-2">
                  <font-awesome-icon
                      icon="exclamation-triangle"
                      class="risk-6"
                  />
                </span>
                <span class="text-normal">
                  {{ $t('system.' + $options.filters.slugify(selectedTask.status)) }}
                </span>
              </div>
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('table.project') }}</strong>
                </div>
                <span v-if="isOwner(selectedTask.project)" class="text-success breadcrumb-item cursor-pointer" @click="navigateToProject(selectedTask)">
                  <font-awesome-icon icon="calendar-alt" class="mr-1"/>
                  {{ selectedTask.project.title }}
                </span>
                <span v-if="!isOwner(selectedTask.project)" class="text-success breadcrumb-item" style="pointer-events:none;">
                  <font-awesome-icon icon="calendar-alt" class="mr-1"/>
                  {{ selectedTask.project.title }}
                </span>
              </div>
              <div class="col text-center pt-3 pb-3">
                <div class="mb-3">
                  <strong>{{ $t('projects.create.owners_label') }}</strong>
                </div>
                <span class="mr-1 text-normal" v-for="user in getReducedUsers(selectedTask.project.users)">
                  <span qid="profile-photo">
                    <Avatar :object="user" size="xs"></Avatar>
                  </span>
                </span>
                <span v-if="selectedTask.project.users.length > 3"
                      class="badge cursor-pointer"
                      @click="openUsersModal(selectedTask.project)"
                >
                  <font-awesome-icon icon="users"/>
                  + {{ selectedTask.project.users.length - 3 }}
                </span>
              </div>
            </div>
          </div>
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">
                <span>
                  <vm-progress
                      type="circle"
                      :percentage="project_progress"
                      style="vertical-align: middle;"
                      :width="50"
                      :stroke-width="2"
                      stroke-color="#00c29e"
                  >
                    {{project_progress}}%
                  </vm-progress>
                </span>
              </div>
              <div class="col-sm-6 text-right">
                <b-button class="ml-3 mt-2"
                          variant="success"
                          @click="completeTask()"
                          :disabled="buttonDisabled"
                          v-if="selectedTask.status != 'completed'"
                          qid="create-p-cancel-button"
                >
                  {{ $t('tasks.modal.complete') }}
                </b-button>
                <b-button class="ml-3 mt-2"
                          variant="success"
                          v-if="selectedTask.status == 'completed'"
                          @click="saveTask()"
                          :disabled="buttonDisabled"
                          qid="create-p-cancel-button"
                >
                  {{ $t('system.save') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>

      </template>
    </b-modal>


    <ConfirmRemoveAttachment
        :object="selectedFile"
        :show="showDeleteAttachmentModal"
        type="file"
        @closeConfirmModal="closeDeleteAttachmentModal"
    ></ConfirmRemoveAttachment>

    <ConfirmRemoveAttachment
        :object="selectedLink"
        :show="showDeleteLinkModal"
        type="link"
        @closeConfirmModal="closeDeleteLinkModal"
    ></ConfirmRemoveAttachment>

    <GuideViewModal
        :guide="selectedGuide"
        :showModal="viewGuideModal"
        @closeViewModal="closeViewGuideModal"
    ></GuideViewModal>

    <ShowMoreModal
        :items="selectedItem.users"
        :show="showUsersModal"
        @closeViewModal="closeUsersModal"
        type="users"
    ></ShowMoreModal>

  </span>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Datepicker from 'vuejs-datepicker';
  import {config} from '@/config.js';
  import ConfirmRemoveAttachment from '@/components/ConfirmRemoveAttachment.vue';
  import GuideViewModal from '@/components/guide/GuideViewModal.vue';
  import roleCheck from "@/util/ACL/roleCheck"
  import Avatar from '@/components/Avatar.vue';
  import ShowMoreModal from '@/components/ShowMoreModal.vue';


  export default {
    name: 'RiskSingleTaskModal',
    data: function() {
      return {
        taskForm: {
          remarks: ''
        },
        selectedTask: [],
        taskList: [],
        selectedAnswer: [],
        allAnswers: [
          { name: this.$t('tasks.modal.pass'), value: 'pass' },
          { name: this.$t('tasks.modal.fail'), value: 'fail' }
        ],
        buttonDisabled: false,
        selectedFile: {},
        selectedLink: {},
        showDeleteAttachmentModal: false,
        showDeleteLinkModal: false,
        showInput: false,
        showSingleLinkLabel: true,
        inputLink: '',
        inputLinkName: '',
        descriptionLength: config.descriptionLength,
        showFullProjectMessage: false,
        showFullGenericControlDescription: false,
        showFullGenericControlPassedDescription: false,
        showFullGenericControlFailedDescription: false,
        error: '',
        project_progress: 0,
        taskCompleted: false,
        welcomeGuide: {},
        selectedGuide: {},
        showFullGuideDescription: false,
        wpGuide: {},
        myGuides: [],
        viewGuideModal: false,
        selectedItem: {},
        showUsersModal: false
      }
    },
    props: {
      task: {}
    },
    components: {
      Datepicker,
      ConfirmRemoveAttachment,
      GuideViewModal,
      Avatar,
      ShowMoreModal
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getWordpressLink() {
        return this.$store.state.wordpressLink
      },
      getUser() {
        return this.$store.state.user
      },
    },
    watch: {
      getClient: function () {

      }
    },
    methods: {
      getReducedUsers(users) {
        let reducedUsers = []
        if (users) {
          if (users.length > 3) {
            reducedUsers = _.cloneDeep(users);
            reducedUsers = reducedUsers.slice(0, 3);
          } else {
            reducedUsers = users;
          }
        }
        return reducedUsers;
      },
      openGuide() {
        if (this.wpGuide && this.wpGuide.link) {
          window.open(this.wpGuide.link, '_blank')
        }
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Task' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Task',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
            piincHttp.get(self.getWordpressLink + self.myGuides[0].external_id).then(function(response) {
              self.wpGuide = response.data
            }, function() {});
          }
        }, function() {});
      },
      getDifference(endDate) {
        var moment = require('moment');
        let currentDate = moment()
        endDate = moment(endDate);
        let difference = endDate.diff(currentDate, 'days');
        return difference;
      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      showTaskModal(object) {

        let self = this;
        this.error = '';
        this.loadGuides('control_task')

        if (object != null) {
          self.selectedTask = object
        }

        if (object == null && this.tasks && this.tasks.length) {
          self.selectedTask = this.tasks[0]
        }

        this.loadTaskById(this.selectedTask.id, true);
        this.populateForm()
        this.$refs['task-modal'].show()
        this.loadTaskGuide()
      },
      hideTaskModal() {
        let self = this;
        this.error = '';

        setTimeout(function(){
          self.$emit('reloadContent');
          self.selectedTask = {}
        }, 500);

        if (!this.taskCompleted && this.selectedTask.status != 'completed') {
          this.saveTask();
          this.minimizeTask();
        }

        if (this.taskCompleted) {
          self.$store.commit('storeReloadTasks');
        }

        this.$refs['task-modal'].hide()
      },
      populateForm() {
        let self = this;

        self.taskForm.remarks = self.selectedTask.remarks

        self.selectedAnswer = []
        if (self.selectedTask.completion_status) {
          self.selectedAnswer = _.find(self.allAnswers, function(item) {
            if(item.value === self.selectedTask.completion_status){
              return self.selectedTask.completion_status;
            }
          });
        }
      },
      openWelcomePage() {
        this.showViewGuideModal(this.welcomeGuide)
      },
      showViewGuideModal(object) {
        this.selectedGuide = object
        this.viewGuideModal = true
      },
      closeViewGuideModal() {
        this.selectedGuide = ''
        this.viewGuideModal = false
      },
      loadTaskGuide() {
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';
        let self = this;
        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Task',
            scope: 'control_task',
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.welcomeGuide = response.data.items[0]
          }
        }, function() {});
      },
      navigateToTask(object) {
        if (this.isOwner(object.model)) {
          this.$store.commit('storeTaskToolbarState', true);
          this.$store.commit('storeActiveTask', object);
          if (object.model_type == 'App\\Activity\\Models\\Activity') {
            this.$router.push({ name: 'activities.view', params: { slug: object.model.slug }, query: { task: object.id }})
          }
          if (object.model_type == 'App\\Process\\Models\\Process') {
            this.$router.push({ name: 'processes.view', params: { slug: object.model.slug }, query: { task: object.id }})
          }
          if (object.model_type == 'App\\System\\Models\\System') {
            this.$router.push({ name: 'systems.view', params: { slug: object.model.slug, }, query: { task: object.id }})
          }
          if (object.model_type == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty') {
            this.$router.push({ name: 'contracts.view', params: { slug: object.model.slug, }, query: { task: object.id }})
          }
          if (object.model_type == 'App\\Maintenance\\Policy\\Models\\Policy') {
            this.$router.push({ name: 'policies.view', params: { slug: object.model.slug, }, query: { task: object.id }})
          }
        }
      },
      navigateToControl(object) {
        if (this.isOwner(object.scope)) {
          this.$store.commit('storeTaskToolbarState', true);
          this.$store.commit('storeActiveTask', object);
          this.$router.push({ name: 'generic_controls.view', params: { slug: object.scope.slug }})
        }
      },
      navigateToProject(task) {
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', task);
        this.$router.push({ name: 'projects.view', params: { slug: task.project.slug }})
      },
      isOwner(object) {
        let self = this;
        let allow = false;
        let existingUser = _.find(object.users, function(user) {
          return user.id == self.getUser.id
        });

        if (existingUser) {
          allow = true
        } else {
          if (roleCheck('Client admin') || roleCheck('Partition admin') || roleCheck('Validator') || this.getUser.isAdmin) {
            allow = true
          }
        }

        return allow
      },
      minimizeTask() {
        var element = document.getElementsByClassName('modal');
        element[0].classList.add("bottom-left");
        // this.submitForm = true
        this.$store.commit('storeActiveTask', this.selectedTask);
        this.$store.commit('storeTaskToolbarState', true);
      },
      saveTask() {
        this.buttonDisabled = true;
        let self = this;

        piincHttp.put('tasks/' + this.selectedTask.id, {
          remarks: this.taskForm.remarks,
          completion_status: this.selectedAnswer && this.selectedAnswer.value ? this.selectedAnswer.value : ''
        }).then(function(response) {
          self.buttonDisabled = false;
          self.$emit('reloadContent', response.data);
          self.$refs['task-modal'].hide()
          self.$toastr('success', self.$t('tasks.modal.success'))
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })

      },
      completeTask() {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        this.error = ''

        if(!this.selectedTask.model || !this.selectedTask.scope){

          piincHttp.put('tasks/' + this.selectedTask.id, {
            remarks: this.taskForm.remarks,
            completion_status: this.selectedAnswer && this.selectedAnswer.value ? this.selectedAnswer.value : undefined,
            status: 'completed'
          }).then(function(response) {
            self.$toastr('success', self.$t('tasks.modal.success'))
            self.buttonDisabled = false;

            if (self.taskList.length > 1) {
              let index = _.findIndex(self.taskList, function(o) { return o.id == self.selectedTask.id })
              if (index < self.taskList.length - 1) {
                self.selectedTask = self.taskList[index + 1]
              } else {
                self.selectedTask = self.taskList[0]
              }
              self.taskList.splice(index, 1);
              self.loadTaskById(self.selectedTask.id);
            } else {
              self.taskCompleted = true;
              self.$store.commit('storeTaskToolbarState', false);
              self.hideTaskModal()
            }
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        }else{
          if (
                  ((this.selectedTask && this.selectedTask.add_attachments == 'Mandatory' && ((this.selectedTask.media && this.selectedTask.media.length) || (this.selectedTask.links && this.selectedTask.links.length))) ||
                          (this.selectedTask && this.selectedTask.add_attachments != 'Mandatory')) &&
                  ((this.selectedTask && this.selectedTask.add_description == 'Mandatory' && this.taskForm.remarks) ||
                          (this.selectedTask && this.selectedTask.add_description != 'Mandatory')) &&
                  (this.selectedAnswer && this.selectedAnswer.value)
          ) {
            piincHttp.put('tasks/' + this.selectedTask.id, {
              remarks: this.taskForm.remarks,
              completion_status: this.selectedAnswer && this.selectedAnswer.value ? this.selectedAnswer.value : undefined,
              status: 'completed'
            }).then(function(response) {
              self.$toastr('success', self.$t('tasks.modal.success'))
              self.buttonDisabled = false;

              if (self.taskList.length > 1) {
                let index = _.findIndex(self.taskList, function(o) { return o.id == self.selectedTask.id })
                if (index < self.taskList.length - 1) {
                  self.selectedTask = self.taskList[index + 1]
                } else {
                  self.selectedTask = self.taskList[0]
                }
                self.taskList.splice(index, 1);
                self.loadTaskById(self.selectedTask.id);
              } else {
                self.taskCompleted = true;
                self.$store.commit('storeTaskToolbarState', false);
                self.hideTaskModal()
              }
            }, function(error) {
              if (error.status !== 200) {
                self.buttonDisabled = false;
                self.error = error.data.message;
              }
            })
          } else {
            this.error = '';
            self.buttonDisabled = false;
            if (!this.selectedAnswer || (this.selectedAnswer && !this.selectedAnswer.value)) {
              this.error += ' ' + this.$t('tasks.modal.response_error_message')
            }
            if ((this.selectedTask.add_attachments == 'Mandatory' && this.selectedTask.media && !this.selectedTask.media.length) && (this.selectedTask.add_attachments == 'Mandatory' && this.selectedTask.links && !this.selectedTask.links.length)) {
              this.error += ' ' + this.$t('tasks.modal.attachments_error_message')
            }
            if (this.selectedTask.add_description == 'Mandatory' && !this.taskForm.remarks) {
              this.error += ' ' + this.$t('tasks.modal.description_error_message')
            }
          }
        }


      },
      previousTask() {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        this.error = ''

        piincHttp.put('tasks/' + this.selectedTask.id, {
          remarks: this.taskForm.remarks,
          completion_status: this.selectedAnswer && this.selectedAnswer.value ? this.selectedAnswer.value : undefined
        }).then(function(response) {
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })

        let index = _.findIndex(self.taskList, function(o) { return o.id == self.selectedTask.id })
        if (index > 0) {
          this.selectedTask = this.taskList[index - 1]
        } else {
          this.selectedTask = this.taskList[this.taskList.length - 1]
        }

        self.loadTaskById(this.selectedTask.id);

      },
      nextTask() {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        this.error = ''

        piincHttp.put('tasks/' + this.selectedTask.id, {
          remarks: this.taskForm.remarks,
          completion_status: this.selectedAnswer && this.selectedAnswer.value ? this.selectedAnswer.value : undefined
        }).then(function(response) {
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })

        let index = _.findIndex(self.taskList, function(o) { return o.id == self.selectedTask.id })
        if (index < this.taskList.length - 1) {
          this.selectedTask = this.taskList[index + 1]
        } else {
          this.selectedTask = this.taskList[0]
        }

        self.loadTaskById(self.selectedTask.id);

      },
      resetFile() {
        let imagefile = document.querySelector('#file');
        imagefile.value = '';
      },
      loadTaskById(id, ignorePopulate) {
        let self = this;
        piincHttp.get('tasks/' + id).then(function(response) {
          self.selectedTask = response.data;
          self.project_progress = self.selectedTask.project.user_progress.progress;
          if (!ignorePopulate) {
            self.populateForm()
          }
        }, function() {});
      },
      openDeleteAttachmentModal(object) {
        this.selectedFile = object
        this.showDeleteAttachmentModal = true
      },
      closeDeleteAttachmentModal () {
        this.selectedFile = {}
        this.showDeleteAttachmentModal = false;
        this.loadTaskById(this.selectedTask.id, true);
      },
      openDeleteLinkModal(object) {
        this.selectedLink = object
        this.showDeleteLinkModal = true
      },
      closeDeleteLinkModal () {
        this.selectedLink = {}
        this.showDeleteLinkModal = false;
        this.loadTaskById(this.selectedTask.id, true);
      },
      handleLink() {
        let self = this;
        let formData = new FormData();

        if (!this.inputLink.startsWith('http')) {
          this.inputLink = 'http://' + this.inputLink
        }

        formData.append("url", this.inputLink);
        formData.append("name", this.inputLinkName);
        formData.append("model_type", this.selectedTask.who_am_i);
        formData.append("model_id", this.selectedTask.id);

        piincHttp.post('links', formData).then(function() {
          self.inputLink = ''
          self.inputLinkName = ''
          self.loadTaskById(self.selectedTask.id, true);
          self.showInput = false
        }, function() {})
      },
      downloadMedia(object){
        piincHttp.get('documents/' + object.id + '/download', {
          responseType: 'blob',
          params:
            {
              client: this.getClient.slug,
              task: this.selectedTask.id
            }
        }).then(function(response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = object.file_name;

          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);
        }, function() {});
      },
      downloadScopeMedia(object){
        piincHttp.get('tasks/'+this.selectedTask.id+'/documents/' + object.id + '/download', {
          responseType: 'blob',
          params:
                  {
                    client: this.getClient.slug
                  }
        }).then(function(response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = object.file_name;

          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);
        }, function() {});
      },
      onFileChange(file) {
        let files = file.target.files;
        let self = this;
        let formData = new FormData();
        let sizeError = false;

        for (var i = 0; i < files.length; i++) {
          // Equal to 10 MB
          if (files[i].size > 10485760) {
            sizeError = true;
          }
          formData.append('document[]', files[i]);
        }

        formData.append("name", "documents");
        formData.append("model_type", this.selectedTask.who_am_i);
        formData.append("model_id", this.selectedTask.id);

        if (sizeError) {
          this.error = this.$t('system.file_size')
        } else {
          piincHttp.post('documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function() {
            self.error = ''
            self.loadTaskById(self.selectedTask.id, true);
          }, function(error) {
            self.error = error.data.message;
            if (error.data.errors['document.0']) {
              self.error += ' ' + error.data.errors['document.0'][0]
            }
          })
        }
      },
    },
    created () {

    }
  }
</script>
