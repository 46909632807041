<template>
  <div>
    <!-- View observation modal template -->
    <b-modal ref="view-lb" hide-footer @hide="hideLBModal">
      <div class="d-block text-center">
        <h4 qid="update-tp-title">
          {{ $t('projects.observations.view.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>

      <div v-if="object" qid="lb-article-name">
        <span class="mr-2" v-if="object.impact_assessment == 'Very low'">
          <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-1 mr-2'"
          />
          {{ $t('risk.very_low') }}
        </span>
        <span class="mr-2" v-if="object.impact_assessment == 'Low'">
          <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-3 mr-2'"
          />
          {{ $t('risk.low') }}
        </span>
        <span class="mr-2" v-if="object.impact_assessment == 'High'">
          <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-9 mr-2'"
          />
          {{ $t('risk.high') }}
        </span>
        <span class="mr-2" v-if="object.impact_assessment == 'Very high'">
          <font-awesome-icon
                  icon="exclamation-triangle"
                  :class="'risk-16 mr-2'"
          />
          {{ $t('risk.very_high') }}
        </span>
      </div>
      <div v-if="object" qid="lb-article-name" class="mt-3">
        <font-awesome-icon size="lg" icon="binoculars" class="mr-2"/>
        {{ object.name }}
      </div>
      <div v-if="object && object.scope && project.type != 'control_assessment'" qid="lb-article-name" class="mt-3">
        <font-awesome-icon
                icon="desktop"
                size="lg"
                class="mr-2"
                v-if="object.scope.who_am_i == 'App\\System\\Models\\System'"
        />
        <font-awesome-icon
                icon="cubes"
                size="lg"
                class="mr-2"
                v-if="object.scope.who_am_i == 'App\\Activity\\Models\\Activity'"
        />
        <font-awesome-icon
                icon="cube"
                size="lg"
                class="mr-2"
                v-if="object.scope.who_am_i == 'App\\Process\\Models\\Process'"
        />
        <font-awesome-icon
                icon="file-signature"
                size="lg"
                class="mr-2"
                v-if="object.scope.who_am_i == 'App\\Maintenance\\ThirdParty\\Models\\ThirdParty'"
        />
        {{ object.scope.name }}
      </div>
      <div v-if="object && object.scope && project.type == 'control_assessment'" qid="lb-article-name" class="mt-3">
        <label class="mt-3" v-if="object && object.description">
          Assessment task
        </label>
        <div>
          {{ object.scope.title }}
        </div>
      </div>
      <br>

      <label class="mt-3" v-if="object && object.description">
        {{ $t('projects.observations.create.description_label') }}
      </label>
      <br>
      <span v-if="object && object.description"
            class="text-formatted"
            qid="lb-article-description"
      >
        {{ object.description }}
      </span>
      <br>

      <!--<div class="mt-4">-->
        <!--<p class="text-dark">-->
          <!--{{ $t('attachments.label') }}-->
          <!--&nbsp;-->
          <!--<span class="text-muted">({{ $t('attachments.file_size_label') }})</span>-->
        <!--</p>-->
        <!--<p v-for="(file, index) in object.media" v-bind:key="file.id">-->
          <!--<span class="float-right text-muted cursor-pointer"-->
                <!--@click="showFileModal(file, index)"-->
                <!--qid="remove-media-item"-->
                <!--v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && project.status != 'closed' && project.status != 'completed'"-->
          <!--&gt;-->
            <!--<font-awesome-icon icon="trash-alt"/>-->
          <!--</span>-->
          <!--<span class="mr-3 float-right text-muted cursor-pointer"-->
                <!--@click="downloadMedia(file)"-->
                <!--qid="download-media"-->
          <!--&gt;-->
            <!--<font-awesome-icon icon="cloud-download-alt"/>-->
          <!--</span>-->
          <!--<a @click="downloadMedia(file)" qid="create-tp-media-item">-->
            <!--{{ file.file_name }}-->
          <!--</a>-->
        <!--</p>-->
        <!--<label v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && project.status != 'closed' && project.status != 'completed'"-->
               <!--class="btn btn-secondary cursor-pointer"-->
               <!--qid="create-tp-file-button"-->
        <!--&gt;-->
          <!--<input type="file"-->
                 <!--name="fileupload"-->
                 <!--value="fileupload"-->
                 <!--multiple-->
                 <!--id="file"-->
                 <!--@change="onFileChange"-->
                 <!--@click="resetFile"-->
          <!--&gt;-->
          <!--<span>-->
            <!--<font-awesome-icon icon="cloud-upload-alt"/>-->
            <!--{{ $t('system.upload') }}-->
          <!--</span>-->
        <!--</label>-->
        <!--<br>-->
      <!--</div>-->
      <!--<div class="mt-4">-->
        <!--<p class="text-dark">{{ $t('links.label') }}</p>-->

        <!--<div v-if="object.links">-->
          <!--<p v-for="(link, index) in object.links" v-bind:key="link.id">-->
            <!--<span class="float-right text-muted cursor-pointer"-->
                  <!--@click="showLinkModal(link, index)"-->
                  <!--qid="view-tp-remove-link"-->
                  <!--v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && project.status != 'closed' && project.status != 'completed'"-->
            <!--&gt;-->
              <!--<font-awesome-icon icon="trash-alt"/>-->
            <!--</span>-->
            <!--<a :href="link.url" target="_blank" qid="create-tp-link-item">-->
              <!--{{ link.name ? link.name : link.url }}-->
            <!--</a>-->
          <!--</p>-->
        <!--</div>-->
        <!--<div v-if="showInput">-->
          <!--<label class="mt-2">{{ $t('system.link') }}</label>-->
          <!--<b-form-input-->
                  <!--type="text"-->
                  <!--aria-describedby="inputGroup"-->
                  <!--v-model="inputLink"-->
                  <!--:placeholder="$t('maintenance.third_parties.create.links_placeholder')"-->
                  <!--qid="create-p-links"-->
          <!--&gt;</b-form-input>-->
          <!--<label class="mt-2">{{ $t('table.name') }}</label>-->
          <!--<b-form-input-->
                  <!--type="text"-->
                  <!--aria-describedby="inputGroup"-->
                  <!--v-model="inputLinkName"-->
                  <!--:placeholder="inputLink ? inputLink : $t('table.name')"-->
                  <!--qid="create-p-links"-->
          <!--&gt;</b-form-input>-->
          <!--<div class="text-right mt-2">-->
            <!--<span class="btn btn-success cursor-pointer mr-0"-->
                  <!--id="inputGroup"-->
                  <!--@click="handleLink"-->
                  <!--qid="handle-link-save"-->
            <!--&gt;-->
              <!--{{ $t('system.save') }}-->
            <!--</span>-->
          <!--</div>-->
        <!--</div>-->
        <!--<span v-if="isGranted([permissions.PROJECT_EDIT, permissions.PROJECT_EDIT_OWN], project) && project.status != 'closed' && project.status != 'completed'">-->
          <!--<span class="btn btn-link cursor-pointer"-->
                <!--@click="showInput = true"-->
                <!--qid="add-another-link"-->
          <!--&gt;-->
            <!--<font-awesome-icon icon="plus"/> {{ $t('system.add_link') }}-->
          <!--</span>-->
        <!--</span>-->
        <!--<br>-->
      <!--</div>-->

      <b-button class="mt-4 float-right"
                variant="success"
                @click="hideLBModal"
                qid="create-p-cancel-button"
      >
        {{ $t('processes.legal_basis.view.close') }}
      </b-button>
      <br>

    </b-modal>

    <!-- Delete attachment modal -->
    <b-modal ref="delete-attachment-modal" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-s-title">
          {{ $t('attachments.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        <span>
          {{ $t('attachments.delete.are_you_sure_file') }}
        </span>
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideFileModal"
                  qid="delete-attachment-cancel-button"
        >
          {{ $t('attachments.delete.cancel') }}
        </b-button>
        <b-button @click="removeMediaItem(selectedFileObject, selectedFileIndex)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-attachment-submit-button"
        >
          {{ $t('attachments.delete.submit') }}
        </b-button>
      </div>
    </b-modal>

    <!-- Delete link modal -->
    <b-modal ref="delete-link-modal" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-s-title">
          {{ $t('attachments.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        <span>
          {{ $t('attachments.delete.are_you_sure_link') }}
        </span>
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideLinkModal"
                  qid="delete-link-cancel-button"
        >
          {{ $t('attachments.delete.cancel') }}
        </b-button>
        <b-button @click="removeLink(selectedLinkObject, selectedLinkIndex)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-link-submit-button"
        >
          {{ $t('attachments.delete.submit') }}
        </b-button>
      </div>
    </b-modal>

  </div>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'

  export default {
    name: 'ObservationViewModal',
    data: function () {
      return {
        lbForm: {
          links: []
        },
        inputLink: '',
        inputLinkName: '',
        selectedArticle: [],
        articles: [],
        error: '',
        showInput: false,
        showSingleLinkLabel: true,
        loadingItems: false,
        showArticleDescription: false,
        selectedFileObject: {},
        selectedFileIndex: '',
        selectedLinkObject: {},
        selectedLinkIndex: '',
      }
    },
    props: {
      object: {
        type: [Object, Array]
      },
      project: {
        type: Object
      },
      showModal: {
        type: Boolean
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getMedia() {
        return this.object.media
      }
    },
    watch: {
      // Watch when prop changes
      showModal: function (value) {
        if (value) {
          this.showLBModal()
        }
      }
    },
    methods: {
      resetFile() {
        let imagefile = document.querySelector('#file');
        imagefile.value = '';
      },
      handleLink() {
        let self = this;
        let formData = new FormData();

        if (!this.inputLink.startsWith('http')) {
          this.inputLink = 'http://' + this.inputLink
        }

        formData.append("url", this.inputLink);
        formData.append("name", this.inputLinkName);
        formData.append("model_type", this.object.who_am_i);
        formData.append("model_id", this.object.id);

        piincHttp.post('links', formData).then(function (response) {
          self.$toastr('success', self.$t('processes.legal_basis.link_add_success'))
          self.object.links = response.data.links
          self.inputLink = ''
          self.inputLinkName = ''
          self.showInput = false
        }, function () {
        })
      },
      showFileModal(object, index) {
        let self = this
        this.selectedFileObject = object;
        this.selectedFileIndex = index;
        this.$refs['delete-attachment-modal'].show()
      },
      hideFileModal() {
        this.$refs['delete-attachment-modal'].hide()
      },
      showLinkModal(object, index) {
        let self = this
        this.selectedLinkObject = object;
        this.selectedLinkIndex = index;
        this.$refs['delete-link-modal'].show()
      },
      hideLinkModal() {
        this.$refs['delete-link-modal'].hide()
      },
      removeLink(object, index) {
        let self = this;
        piincHttp.delete('links/' + object.id).then(function () {
          self.object.links.splice(index, 1);
          self.hideLinkModal();
          self.$toastr('success', self.$t('processes.legal_basis.link_delete_success'))
        }, function () {
        })
      },
      removeMediaItem(object, index) {
        let self = this;
        piincHttp.delete('documents/' + object.id).then(function () {
          self.object.media.splice(index, 1);
          self.hideFileModal();
          self.$toastr('success', self.$t('processes.legal_basis.media_delete_success'))
        }, function () {
        })
      },
      downloadMedia(object) {
        piincHttp.get('documents/' + object.id + '/download', {responseType: 'blob'}).then(function (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = object.file_name;

          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);
        }, function () {
        });
      },
      onFileChange(file) {
        let files = file.target.files;
        let self = this;
        let formData = new FormData();
        let sizeError = false;

        for (var i = 0; i < files.length; i++) {
          // Equal to 10 MB
          if (files[i].size > 10485760) {
            sizeError = true;
          }
          formData.append('document[]', files[i]);
        }

        formData.append("name", "documents");
        formData.append("model_type", this.object.who_am_i);
        formData.append("model_id", this.object.id);

        if (sizeError) {
          this.error = this.$t('system.file_size')
        } else {
          piincHttp.post('documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function (response) {
            self.$toastr('success', self.$t('processes.legal_basis.media_add_success'))
            self.error = ''
            self.object.media = response.data.media
          }, function (error) {
            self.error = error.data.message;
            if (error.data.errors['document.0']) {
              self.error += ' ' + error.data.errors['document.0'][0]
            }
          })
        }
      },

      showLBModal() {
        let self = this
        this.$refs['view-lb'].show()
      },
      hideLBModal() {
        this.error = ''
        this.showArticleDescription = false;
        this.inputLink = ''
        this.$emit('closeViewModal');
        this.$refs['view-lb'].hide()
      }
    },
    components: {},
    created() {

    }
  }
</script>
