<template>
  <span>
    <span class="btn btn-edit" qid="edit-tp-modal" @click="showEditPModal(object)">
      <font-awesome-icon icon="pen"/>
    </span>

    <!-- Edit Process modal template -->
    <b-modal ref="edit-process" @hide="hideEditPModal" modal-class="task-modal" hide-footer>
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideEditPModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="update-user-title">
            {{ $t('projects.update.title') }}
          </h5>
        </div>
      </template>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="create-p-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="formSubmit" qid="create-p-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-p-name-label">
              {{ $t('projects.create.title_label') }}
            </label>
            <b-form-input
              qid="create-p-form-name"
              type="text"
              v-model="sForm.title"
              required
              :placeholder="$t('projects.create.title_placeholder')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-do-tags-label">
              {{ $t('processes.create.tags_label') }}
            </label>
            <multiselect
                v-model="selectedTags"
                :options="tags"
                :multiple="true"
                :taggable="true"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @search-change="loadTags"
                track-by="name_translated"
                :placeholder="$t('processes.create.tags_placeholder')"
                label="name_translated"
                qid="create-do-tags-select"
                @tag="addTag"
            ></multiselect>
          </b-form-group>
          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('projects.create.owners_label') }}
            </label>
            <multiselect
                v-model="selectedUsers"
                :options="users"
                :multiple="true"
                :taggable="false"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @search-change="loadUsers"
                track-by="slug"
                :placeholder="$t('projects.create.owners_placeholder')"
                label="name"
                qid="create-p-users-select"
            >
              <template slot="tag" slot-scope="props">
                <span class="mr-3 user-tag">
                  <Avatar :object="props.option" size="xs"></Avatar>
                  <span class="ml-2">{{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
          </b-form-group>
          <b-form-group v-if="object.type === 'control_assessment' || object.type === 'risk_assessment'">
            <label qid="create-p-users-label">
              {{ $t('projects.create.project_type') }}
            </label>
            <b-form-radio-group
                    v-model="sForm.project_type"
                    :options="typeOptions"
            ></b-form-radio-group>
          </b-form-group>
          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('projects.create.include_relations') }}
            </label>
            <b-form-radio-group
                    v-model="sForm.include_relations"
                    :options="yesNoOptions"
            ></b-form-radio-group>
          </b-form-group>
          <b-form-group v-if="object.type == 'control_assessment'">
            <label qid="create-p-users-label">
              {{ $t('maintenance.assessments.include_mapping_title') }}
            </label>
            <b-form-radio-group
              v-model="sForm.include_mapping"
              :options="yesNoOptions"
            ></b-form-radio-group>
          </b-form-group>
          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('projects.create.overdue_reminder') }}
            </label>
            <b-form-radio-group
              v-model="sForm.overdue_notifications"
              :options="yesNoOptions"
            ></b-form-radio-group>
          </b-form-group>
          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('projects.create.external_link_label') }}
            </label>
            <div class="mt-2">
              <div class="float-right">
                <span class="btn btn-secondary cursor-pointer" @click="showConfirmDeleteCOModal" v-if="selectedExternalLink && selectedExternalLink.name">
                  {{ $t('projects.create.external_link_remove_button') }}
                </span>
                <span class="btn btn-secondary cursor-pointer" @click="showImportModal" v-else>
                  {{ $t('projects.create.external_link_change_button') }}
                </span>
              </div>
              <div v-if="selectedExternalLink && selectedExternalLink.name">
                <Avatar :object="selectedExternalLink" size="xs"></Avatar>
                <span class="ml-2">
                  {{ selectedExternalLink.name }}
                </span>
              </div>
              <div v-else>
                {{ $t('system.n_a') }}
              </div>
            </div>
          </b-form-group>
        </div>

        <div class="proxy-footer">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class="mt-1"
                            variant="secondary"
                            @click="hideEditPModal"
                            qid="create-p-cancel-button"
                  >
                    {{ $t('projects.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="mt-1 ml-3"
                            variant="success"
                            :disabled="buttonDisabled"
                            qid="create-p-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('projects.create.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </b-form>

    </b-modal>


    <!-- Delete Control Objective confirm modal template -->
    <b-modal ref="delete-external-link" modal-class="task-modal">
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideConfirmDeleteCOModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="update-m-title">
            {{ $t('projects.create.external_link_remove_button') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="delete-co-error"
      >
        {{ error }}
      </b-alert>

      <div class="mb-2 mt-3" style="font-size:18px;">
        <div>
          {{ $t('maintenance.control_objective.delete.description') }}
          <span class="text-bold">
            {{ itemToDelete.name }}
          </span>
        </div>
        <div class="mt-2">
          {{ $t('maintenance.control_objective.delete.are_you_sure') }}
        </div>
      </div>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class="mt-3"
                            variant="secondary"
                            @click="hideConfirmDeleteCOModal"
                            qid="delete-co-cancel-button"
                  >
                    {{ $t('maintenance.control_objective.delete.cancel') }}
                  </b-button>
                  <b-button @click="confirmDeleteCO(itemToDelete)"
                            class="mt-3 ml-3"
                            variant="danger"
                            qid="delete-co-submit-button"
                            :disabled="buttonDisabled"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('maintenance.control_objective.delete.submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>


    <b-modal ref="import-modal" @hide="hideImportModal" modal-class="task-modal" hide-footer>
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideImportModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="update-user-title">
            {{ $t('projects.create.external_link_label') }}
          </h5>
        </div>
      </template>

      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="edit-user-error"
      >
        {{ error }}
      </b-alert>

      <b-form @submit="importFormSubmit" qid="edit-user-form">
        <div class="form-container">
          <b-form-group>
            <label qid="create-user-upn-email-label">
              {{ $t('maintenance.measures.client_label') }}
            </label>
            <multiselect
                v-model="selectedClient"
                :options="clients"
                :multiple="false"
                track-by="slug"
                :placeholder="$t('maintenance.measures.client_placeholder')"
                label="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @select="clientSelected"
                qid="create-p-users-select"
            >
              <template slot="singleLabel" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
          </b-form-group>
          <b-form-group v-if="selectedClient && selectedClient.id">
            <label qid="create-user-upn-email-label">
              {{ $t('maintenance.measures.partition_label') }}
            </label>
            <multiselect
                v-model="selectedPartition"
                :options="partitions"
                :multiple="false"
                track-by="slug"
                :placeholder="$t('maintenance.measures.partition_placeholder')"
                label="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @search-change="loadPartitions"
                qid="create-p-users-select"
            >
              <template slot="singleLabel" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
          </b-form-group>
        </div>
        <div class="proxy-footer">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="hideImportModal"
                            qid="edit-user-cancel-button"
                  >
                    {{ $t('administration.users.create.cancel') }}
                  </b-button>
                  <b-button type="submit"
                            class="ml-3"
                            variant="success"
                            :disabled="buttonDisabled"
                            qid="edit-user-submit-button"
                  >
                    <span v-if="buttonDisabled" class="mr-1">
                      <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                    </span>
                    {{ $t('maintenance.users.merge_users_submit') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-modal>

  </span>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';

  export default {
    name: 'ActivityEditForm',
    data: function() {
      return {
        sForm: {
          title: '',
          users: [],
          tags: [],
          include_mapping: '',
          include_relations: '',
          overdue_notifications: '',
          project_type: ''
        },
        itemToDelete: {},

        partitions: [],
        clients: [],
        selectedPartition: [],
        selectedClient: [],
        selectedLink: [],
        selectedExternalLink: [],

        error: '',
        selectedUsers: [],
        users: [],
        editParam: false,
        modalShown: false,
        buttonDisabled: false,
        selectedTags: [],
        tags: [],
        newTags: [],
        yesNoOptions: [
          {text: this.$t('system.yes'), value: 1},
          {text: this.$t('system.no'), value: 0},
        ],
        typeOptions: [
          {text: this.$t('system.type_one'), value: 'type one'},
          {text: this.$t('system.type_two'), value: 'type two'},
        ]
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      },
      edit: {
        type: Boolean,
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getUser() {
        return this.$store.state.user
      },
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadTags();
        this.loadUsers();
      },
      object: function (value) {
        let self = this;
        if (value && value.id) {
          if (this.editParam && !this.modalShown) {
            setTimeout(function(){
              self.showEditPModal(self.object)
              self.modalShown = true;
            }, 500);
          }
        }
      }
    },
    methods: {
      addTag(newTag) {
        const tag = {
          name: newTag,
          translatable: newTag,
          name_translated: newTag
        }
        this.selectedTags.push(tag)
        this.newTags.push(tag)
      },
      showConfirmDeleteCOModal() {
        let self = this;
        this.error = '';
        self.itemToDelete = this.selectedExternalLink
        this.$refs['delete-external-link'].show()
      },
      hideConfirmDeleteCOModal() {
        this.itemToDelete = {}
        this.$refs['delete-external-link'].hide()
      },
      confirmDeleteCO(object) {
        this.selectedExternalLink = []
        this.hideConfirmDeleteCOModal()
      },
      showEditPModal(object) {
        let self = this;

        this.loadTags();
        this.loadUsers();

        if (object.users && object.users.length) {
          self.selectedUsers = [];
          _.forEach(object.users, function (user) {
              self.selectedUsers.push(user)
          })
        }

        if (object.tags_translated && object.tags_translated.length) {
          this.selectedTags = _.cloneDeep(object.tags_translated);
        }

        this.sForm.slug = object.slug;
        this.sForm.activity = object.slug;
        this.sForm.include_mapping = object.include_mapping;
        this.sForm.include_relations = object.include_relations;
        this.sForm.project_type = object.project_type;
        this.sForm.overdue_notifications = object.overdue_notifications;

        if (object.additional_client) {
          this.selectedExternalLink = object.additional_client;
        }

        if (object.include_mapping == null) {
          this.sForm.include_mapping = 0
        }
        if (object.include_relations == null) {
          this.sForm.include_relations = 0
        }
        if (object.project_type == null) {
          this.sForm.project_type = 'type one'
        }
        if (object.overdue_notifications == null) {
          this.sForm.overdue_notifications = 0
        }

        this.sForm.title = object.title;
        this.$refs['edit-process'].show()

      },
      hideEditPModal() {
        this.sForm = {}
        this.error = '',
        this.selectedUsers = [],
        this.selectedExternalLink = [],
        this.editParam = false;
        this.$refs['edit-process'].hide()
      },
      formSubmit(evt) {
        this.buttonDisabled = true;
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        this.sForm.users = [];
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            this.sForm.users.push(this.selectedUsers[i].id);
          }
        }

        this.sForm.tags = [];
        if (this.selectedTags && this.selectedTags.length) {
          for (var i = 0; i < this.selectedTags.length; i++) {
            this.sForm.tags.push(this.selectedTags[i].name_translated);
          }
        }

        if (this.selectedExternalLink && this.selectedExternalLink.slug) {
          this.sForm.external_client = this.selectedExternalLink.slug
        } else {
          this.sForm.external_client = ''
        }

        piincHttp.put('projects/' + this.sForm.slug, this.sForm).then(function(response) {
          self.hideEditPModal();
          // Emit event to parent component to update newest validation
          self.$emit('reloadContent', response.data);
          self.$toastr('success', self.$t('projects.update.success'))
          self.buttonDisabled = false;
        }, function(error) {
          if (error.status !== 200) {
            self.buttonDisabled = false;
            self.error = error.data.message;
          }
        })
      },
      loadTags(query) {
        let self = this;
        piincHttp.get('tags', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.tags = response.data
          if (self.tags && !self.tags.length && !query) {
            self.tags.push({name_translated: self.$t('system.no_results'), $isDisabled: true})
          }
          for (var i = 0; i < self.newTags.length; i++) {
            self.tags.push(self.newTags[i]);
          }
        }, function() {});
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      clientSelected() {
        let self = this
        this.selectedPartition = []
        setTimeout(function(){
          self.loadPartitions()
        }, 300);
      },
      showImportModal(object) {
        let self = this;
        this.error = '';
        this.selectedClient = []
        this.selectedPartition = []

        this.loadClients()

        this.$refs['import-modal'].show()
      },
      hideImportModal() {
        this.$refs['import-modal'].hide()
      },
      importFormSubmit(evt) {
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        if (evt) {
          evt.preventDefault();
        }

        if ((this.selectedClient && this.selectedClient.id) || (this.selectedPartition && this.selectedPartition.id)) {
          this.selectedExternalLink = this.selectedPartition && this.selectedPartition.slug ? this.selectedPartition : this.selectedClient
          this.hideImportModal()
        } else {
          this.selectedExternalLink = []
          this.hideImportModal()
        }
      },
      loadPartitions() {
        let self = this;
        piincHttp.get('partitions', { params: {
          client: self.selectedClient && self.selectedClient.id ? self.selectedClient.slug : undefined,
          per_page: 0,
          sort: 'name',
          order: 'asc',
          manage_measures: 1,
          mine: 1
        }}).then(function(response) {
          self.partitions = [];
          _.forEach(response.data.items, function (item) {
            if (item.id != self.getClient.id){
              self.partitions.push(item);
            }
          })
        }, function() {});
      },
      loadClients(query) {
        let self = this;
        piincHttp.get('clients', { params: {
          per_page: 100,
          without_partition: 1,
          member: !this.getUser.isAdmin ? this.getUser.id : undefined,
          keyword: query ? query : undefined
        }}).then(function(response) {
          self.clients = response.data.items;
        }, function() {});
      },
    },
    components: {
      Avatar
    },
    created () {
      this.editParam = this.edit;
      let self = this;
      if (this.object && this.object.id) {
        if (this.editParam && !this.modalShown) {
          setTimeout(function(){
            self.showEditPModal(self.object)
            self.modalShown = true;
          }, 500);
        }
      }
    }
  }
</script>
