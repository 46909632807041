<template>
  <span>
    <button class="btn pl-3 pr-3 btn-secondary"
            @click="showDownloadModal()"
    >
      <font-awesome-icon icon="cloud-download-alt"/> {{ $t('projects.download_attachments_button') }}
    </button>


    <!-- Download attachments modal -->
    <b-modal ref="download-modal"
             modal-class="task-modal"
             @hide="hideDownloadModal"
    >
      <template #modal-header="{ close }">
        <div class="" style="width: 100%;">
          <span @click="hideDownloadModal" class="btn btn-secondary cursor-pointer float-right ml-2 mr-0">
            <font-awesome-icon icon="times"/>
          </span>
          <h5 class="mb-1 text-center" qid="delete-user-title">
            {{ $t('projects.download_attachment_modal_title') }}
          </h5>
        </div>
      </template>

      <div class="mb-2 mt-3 text-center">
        <b-alert v-if="error.length"
                 show
                 variant="danger"
                 qid="create-p-error"
        >
          {{ error }}
        </b-alert>
        <h6>
          {{ $t('projects.download_attachments_description') }}
        </h6>
        <h5>
          {{ $t('projects.are_you_sure_label') }}
        </h5>
      </div>

      <template #modal-footer="{ close }">
        <div class="" style="width: 100%;">
          <div class="alert alert-menu-color color-secondary mb-0" role="alert">
            <div class="row">
              <div class="col-sm-6">

              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <b-button class=""
                            variant="secondary"
                            @click="hideDownloadModal"
                            qid="delete-user-cancel-button"
                  >
                    {{ $t('administration.users.delete.cancel') }}
                  </b-button>
                  <b-button @click="downloadProcessOverviewFile()"
                            class="ml-3"
                            variant="success"
                            qid="delete-user-submit-button"
                  >
                    {{ $t('system.download') }}
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

    </b-modal>


  </span>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'

  export default {
    name: 'DownloadAllAttachmentsModal',
    data: function() {
      return {

        activity: {},
        sTableItems: [],
        selectedS: [],
        viewSModal: false,
        sLoading: true,

        DOItems: [],
        tasks: [],
        selectedTask: [],

        error: '',
        itemToDelete: {},
        buttonDisabled: false,

        selectedFiles: [],
        inputLink: '',
        inputLinkName: '',
        showDocuments: false,
        selectedImpact: [],
        scopeItems: [],
        selectedScope: []
      }
    },
    props: {
      object: {},
    },
    components: {
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
      },
      object: function (value) {
        this.activity = value;
      }
    },
    methods: {
      showDownloadModal() {
        this.$refs['download-modal'].show()
      },
      hideDownloadModal() {
        this.error = ''
        this.$refs['download-modal'].hide()
      },
      downloadProcessOverviewFile(){
        //this.successMessage = ''
        let self = this;

        piincHttp.get('projects/'+this.activity.slug+'/download-attachments').then(function(response) {
          if (response.status == 200) {
            // self.successMessage = self.$t('reports.success_message');
            self.$toastr('success', self.$t('projects.download_attachments_success'))
          }
          self.$refs['download-modal'].hide()
        }, function(error) {
          self.error = self.$t('reports.something_went_wrong');
        });
      }
    },
    created () {
      this.activity = this.object;
      if (this.getClient && this.getClient.slug) {

      }

    }
  }
</script>
